import React from 'react';
import styled from 'styled-components';
import { IYesNo } from './types';

const Wrapper = styled.div`
  > button {
    outline: none;
    text-align: center;

    font-size: 18px;
    border: 1px #F35C28 solid;
    border-radius: 25px;
    color: #F35C28;
    background: white;
    cursor: pointer;
    padding: 8px 10px;
    line-height: 0.7;

    width: 49%;
    &.yes { margin-right: 2%; }
    &.active { color:white; background: #F35C28; border: 1px #F35C28 solid; }
  }
`;

export const YesNo = (params: IYesNo) => {
  const { value, onChange } = params;
  const yesClass = `${value === true ? 'active': ''} yes`;
  const noClass = `${value === false ? 'active': ''} no`;
  return (
    <Wrapper>
      <button className={yesClass} onClick={() => onChange(true)}>
        YES
      </button>
      <button className={noClass} onClick={() => onChange(false)}>
        NO
      </button>
      {value}
    </Wrapper>
  );
}

