import { connect } from 'react-redux';
import { HowLongMightYourRetirementBe } from './../components/HowLongMightYourRetirementBe';

const mapStateToProps = (state: any) => (state);
const mapDispatchToProps = (dispatch: any) => ({
  saveScreenshot: (screenshotId: string, dataURL: string) => {
    dispatch({ type: 'SAVE_SCREENSHOT', payload: { screenshotId, dataURL } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(HowLongMightYourRetirementBe);
