import React, { useEffect } from 'react';
import { CenteredHeader, PageContainer, Header, Footer, Wizard, Back, Next } from './Layout';
import { Panel, FourColumns, InfoIcon, InfoBigValue, InfoLabel } from './Grid';
import { toPounds } from './../services/Formats';
import { takeScreenshot } from './../services/Screenshots';
import { triggerResize } from './../services/screen';
import { InfoTip } from './Form/InfoTip';
//import { YesNo } from './Form/YesNo';
import { ISummary } from './types';

export const Summary = (props: ISummary) => {
  //const { keyPartnerships, onChangeInclude } = props;
  const { keyPartnerships, saveScreenshot } = props;
  //const { input, output } = keyPartnerships;
  const { output, input: { withPartner } } = keyPartnerships;
  //const { include } = input;
  const { summary } = output;
  const { savingsMinusDebt, combinedStatePensionPerYear, privatePensions, equityAvailableToRelease, ltv } = summary;
  
  const tip1 = `
  This figure accounts for your client's savings minus
  their outstanding mortgage and unsecured
  debt taken into their retirement
  `;
  const tip2 = `
  The total amount of equity available is based on the 
  retirement age of the client (youngest in joint mortgage cases) and the 
  anticipated property value at retirement. 
  Based on this information, an LTV of ${ltv || '??'}% has been used. 
  Depending on your client’s health and lifestyle conditions, 
  they could be entitled to release more with an enhanced LTV 
  (subject to completing a medical questionnaire).
  `;

  const elementId = 'summary';
  useEffect(() => {
    triggerResize('summary');
    takeScreenshot(elementId).then(dataURL => {
      saveScreenshot(elementId, dataURL);
    });
  }, [saveScreenshot]);

  //const hideMessage = savingsMinusDebt > 0 || equityAvailableToRelease > Math.abs(savingsMinusDebt);
  return (
    <PageContainer>
      <Header title="Your client's assets" />
      <Panel>
        <CenteredHeader>
          Here is a summary of your client's anticipated assets at retirement
        </CenteredHeader>
        <FourColumns id={elementId}>
          <div>
            <InfoIcon src="images/infographics/summary1.svg" alt="" />
            <InfoBigValue>
              {toPounds(savingsMinusDebt)}
              <InfoTip value={tip1} />
            </InfoBigValue>
            <InfoLabel>Savings minus debt</InfoLabel>
          </div>
          <div>
            <InfoIcon src="images/infographics/summary2.svg" alt="" />
            <InfoBigValue>{toPounds(combinedStatePensionPerYear)}</InfoBigValue>
            <InfoLabel>{withPartner ? 'Combined state pension' : 'State pension'}<br />(per year)</InfoLabel>
          </div>
          <div>
            <InfoIcon src="images/infographics/summary3.svg" alt="" />
            <InfoBigValue>{toPounds(privatePensions)}</InfoBigValue>
            <InfoLabel>Private pensions</InfoLabel>
          </div>
          <div>
            <InfoIcon src="images/infographics/summary4.svg" alt="" />
            <InfoBigValue>
              {toPounds(equityAvailableToRelease)}
              <InfoTip orientation='right' value={tip2} />
            </InfoBigValue>
            <InfoLabel>
              {/* Equity available to release<br />
              from your client’s home<br />
              with a lifetime mortgage<br /> */}
              Equity available to release<br />
              from your client's home with <br />
              a lifetime mortgage at retirement<br />
              <div style={{ fontSize: 'xx-small' }}>(You need to be aged 55 and over to qualify for equity release)</div>
            </InfoLabel>
          </div>
        </FourColumns>
      </Panel>

      <Wizard>
        <Back label="YOUR CLIENT'S DETAILS" href="#/your-details" />
        <div className="flex"></div>
        {/* {(!hideMessage) ? (
          <div className="flex">
            <div style={{ textAlign: 'center', fontSize: 12, paddingBottom: 10 }}> 
              You won’t currently be eligible for
              a lifetime mortgage. Do you have
              alternative funds available to pay
              your mortgage debt off in full?
            </div>
            <YesNo value={include.lifetimeEligible} onChange={(v: any) => onChangeInclude('lifetimeEligible', v)} />
          </div>
        ) : false} */}
        <div className="flex"></div>
        <Next label="NEXT" href="#/how-long-might-your-retirement-be" />
      </Wizard>

      <Footer />
    </PageContainer>
  );};
