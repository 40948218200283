import { createStore, applyMiddleware, compose } from 'redux';
import persistState from './services/persistState';
// import thunk from 'redux-thunk';
// import { routerMiddleware } from 'react-router-redux';
// import createHistory from 'history/createHashHistory';
import rootReducer from './reducers';

// export const history = createHistory();
// const routerMiddleware = history => {
//   return () => {
//     return (next) => {
//       return (action) => {
//         const { type, payload } = action;
//         if (type !== 'CALL_HISTORY_METHOD') {
//            return next(action);
//         }
//         const { method, args } = payload
//         history[method].apply(history, args);
//       };
//     };
//   };
// }

const middleware: any[] = [
//   thunk,
   // routerMiddleware(history)
];

// const createStoreWithMiddleware = compose(
//   applyMiddleware(...middleware)(createStore)
// );

export function configure(initialState?: any) {
// Create the redux store and add middleware to it
  // var configStore = createStoreWithMiddleware(
  //   rootReducer,
  //   initialState,
  // );
  // return configStore;
  
  const store = createStore(
    rootReducer, 
    initialState, 
    compose(
      applyMiddleware(...middleware),
      persistState()
    )
  );
  return store;
};
