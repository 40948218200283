import React, { useEffect } from 'react';
import { PageContainer, Header, Footer, Wizard, Next, OrangeButton } from './Layout';
import { TwoColumns, FormRow, RowLabel, RowLabel2, RowContent } from './Grid';
import { InfoTip } from './Form/InfoTip';
import { PoundsInput } from './Form/PoundsInput';
import SliderInput from './Form/SliderInput';
// import RangerInput from './Form/RangerInput';
import { Calendar } from './Form/Calendar'; 
import { Gender } from './Form/Gender';
import { YesNo } from './Form/YesNo';
import { TextOutput } from './Form/TextOutput';
import { IYourDetails } from './types';
import { toPounds } from './../services/Formats';
import { triggerResize } from './../services/screen';

const tipPension = `
Selecting 'yes' assumes you will receive the full state pension and the
maximum joint pension in the case of couples.
`;
const tipAnticipatedValue = `
Please note, house prices can also decrease.
`;
const tipOutstandingDebt = `
Any outstanding debt that you
can’t repay by other means will be taken
away from savings
`;

export const YourDetails = (props: IYourDetails) => {
  useEffect(() => {
    triggerResize('your-details');
  }, []);
  const { keyPartnerships, onChangeInput, onChangePartner } = props;
  const { input, output } = keyPartnerships;
  const onChange = onChangeInput;
  const onChangePounds = (f: any, v: any) => (onChange(f, (v === '')  ? 0: v));
  const onAddPartner = () => { 
    onChange('withPartner', true);
    triggerResize('your-details');
  };
  const onRemovePartner = () => {
    onChange('withPartner', false);
    triggerResize('your-details');
  };

  const { partner } = input;
  return (
    <PageContainer>
      <Header title="Find out how your client's assets could fund their retirement" />
      <TwoColumns>
        <div className="left">
          <h3>Your client's details</h3>
          <FormRow>
            <RowLabel>Date of birth</RowLabel>
            <RowContent style={{ marginRight: 10 }}>
              <Calendar date={input.birthdate} onChange={(v: any) => onChange('birthdate', v)} />
              {/* <SliderInput value={input.age} min={40} max={95} onChange={(v: any) => (onChange('age', v))} /> */}
            </RowContent>
            {/* <SmallTextInput value={input.age} onChange={(v: any) => (onChange('age', v))} /> */}
          </FormRow>
          <FormRow>
            <RowLabel>Gender</RowLabel>
            <RowContent>
              <Gender value={input.gender} onChange={(v: any) =>(onChange('gender', v))} />
            </RowContent>
          </FormRow>
          <FormRow>
            <RowLabel>Retirement age</RowLabel>
            <RowContent style={{ marginRight: 10 }}>
              <SliderInput value={input.retAge} min={55} max={95} onChange={(v: any) => (onChange('retAge', v))} />
            </RowContent>
            {/* <SmallTextInput value={input.retAge} onChange={(v: any) => (onChange('retAge', v))} /> */}
            <TextOutput value={input.retAge.toString()} />
          </FormRow>
          <FormRow>
            <RowLabel style={{ width: 100 }}>
              State pension
            </RowLabel>
            <div style={{ width: 50 }}>
              <InfoTip value={tipPension} />
            </div>
            <RowContent>
              <YesNo value={input.statePension} onChange={(v: any) =>(onChange('statePension', v))} />
            </RowContent>
          </FormRow>
          {input.statePension ? (
            <div>
              <FormRow style={{ fontWeight: 'bold', height: '40px' }}>
                Or you can enter your client's state pension amount below
              </FormRow>
              <FormRow>
                <RowLabel2>State pension amount per month</RowLabel2>
                <RowContent>
                  <PoundsInput value={input.statePensionAmount} onChange={(v: any) => onChange('statePensionAmount', parseFloat(v))} />
                </RowContent>
              </FormRow>
            </div>  
          ) : false}

          {input.withPartner ? (
            <div>
              <div style={{ padding: '30px 0px' }}>
                <OrangeButton style={{ width: '40%' }} onClick={onRemovePartner}>Remove a partner</OrangeButton>
              </div>
              {/* <Clickable onClick={onRemovePartner} style={{ textAlign: 'right' }}>
                Remove Partner
              </Clickable> */}

              <h3>Your Client's Partner's Details</h3>
              <FormRow>
                <RowLabel>Date of Birth</RowLabel>
                <RowContent style={{ marginRight: 10 }}>
                  {/* <SliderInput value={partner.age} min={40} max={95} onChange={(v: any) => (onChangePartner('age', v))} /> */}
                  <Calendar date={partner.birthdate} onChange={(v: any) => (onChangePartner('birthdate', v))} />
                </RowContent>
                {/* <SmallTextInput value={partner.age} onChange={(v: any) => (onChangePartner('age', v))} /> */}
              </FormRow>
              <FormRow>
                <RowLabel>Gender</RowLabel>
                <RowContent>
                  <Gender value={partner.gender} onChange={(v: any) =>(onChangePartner('gender', v))} />
                </RowContent>
              </FormRow>
              <FormRow>
                <RowLabel>Retirement Age</RowLabel>
                <RowContent style={{ marginRight: 10 }}>
                  <SliderInput value={partner.retAge} min={55} max={95} onChange={(v: any) => (onChangePartner('retAge', v))} />
                </RowContent>
                {/* <SmallTextInput value={partner.retAge} onChange={(v: any) => (onChangePartner('retAge', v))} /> */}
                <TextOutput value={partner.retAge.toString()} />
              </FormRow>
              <FormRow>
                <RowLabel style={{ width: 100 }}>
                  State pension
                </RowLabel>
                <div style={{ width: 50 }}>
                  <InfoTip value={tipPension} />
                </div>
                <RowContent>
                  <YesNo value={partner.statePension} onChange={(v: any) =>(onChangePartner('statePension', v))} />
                </RowContent>
              </FormRow>
              {partner.statePension ? (
                <div>
                  <FormRow style={{ fontWeight: 'bold', height: '40px' }}>
                    Or you can enter your client's partner's state pension amount below
                  </FormRow>
                  <FormRow>
                    <RowLabel2>State pension amount per month</RowLabel2>
                    <RowContent>
                      <PoundsInput value={partner.statePensionAmount} onChange={(v: any) => onChangePartner('statePensionAmount', parseFloat(v))} />
                    </RowContent>
                  </FormRow>
                </div>  
              ) : false}
            </div>
          ) : (
            <div style={{ padding: '30px 0px' }}>
              <OrangeButton style={{ width: '40%' }} onClick={onAddPartner}>Add a partner</OrangeButton>
            </div>
          )}

        </div>
        <div className="right">
          <h3>Anticipated assets at retirement</h3>
          <FormRow>
            <RowLabel2>Savings/investments</RowLabel2>
            <RowContent>
              <PoundsInput value={input.saving} onChange={(v: any) => (onChangePounds('saving', v))} />
            </RowContent>
          </FormRow>
          <FormRow>
            <RowLabel2>Private pension/s</RowLabel2>
            <RowContent>
              <PoundsInput value={input.pension} onChange={(v: any) => (onChangePounds('pension', v))} />
            </RowContent>
          </FormRow>
          <FormRow>
            <RowLabel2>Current value of your client's home</RowLabel2>
            <RowContent>
              <PoundsInput value={input.homeVal} onChange={(v: any) => (onChangePounds('homeVal', v))} />
            </RowContent>
          </FormRow>

          <FormRow style={{ marginTop: 0, marginBottom: 10 }}>
            <RowLabel2>
              Value of your client's home at retirement, %
              <small><br />Assuming an annual average HPI %</small>
            </RowLabel2>
            <RowContent style={{ marginRight: 10 }}>
              <TextOutput value={toPounds(output.summary.homeValAtRetirement)} style={{ color: '#6f7287', fontSize: '14', fontWeight: 'bold', paddingLeft: 15 }} />
              <br />
              <SliderInput
                value={input.percentHouse} min={0} max={7} div={4} type='float'
                inactiveColor='#282828' withLabel={true} suffix='%'
                onChange={(v: any) => onChange('percentHouse', v)}
              />
            </RowContent>
            {/* <SmallTextInput
              value={input.percentHouse}
              onChange={(v: any) => onChangePartner('percentHouse', v)} /> */}
              <TextOutput value={`${input.percentHouse}%`} style={{ marginTop: 34 }} />
          </FormRow>

          <FormRow>
            <RowLabel2>
              Or simply enter anticipated<br />
              value here: <InfoTip value={tipAnticipatedValue} orientation='right' />
            </RowLabel2>
            <RowContent>
              {/* TODO: update value and use in calculations */}
              <PoundsInput value={''} onChange={(v: any) => ({})} />
            </RowContent>
          </FormRow>

          <FormRow>
            <RowLabel2>Mortgage value at retirement</RowLabel2>
            <RowContent>
              <PoundsInput value={input.mortgageVal} onChange={(v: any) => (onChangePounds('mortgageVal', v))} />
            </RowContent>
          </FormRow>

          <FormRow>
            <RowLabel2>Outstanding unsecured debt<br />taken into retirement <InfoTip value={tipOutstandingDebt} orientation='right' /></RowLabel2>
            <RowContent>
              <PoundsInput value={input.outstandingDebt} onChange={(v: any) => (onChangePounds('outstandingDebt', v))} />
            </RowContent>
          </FormRow>

        </div>
      </TwoColumns>

      <Wizard>
        <div className="label">
          Please remember these figures are only a guide and are not certain to happen. Everyone is different,
          as are their financial needs.
        </div>
        <div className="flex"></div>
        <Next chevron={true} label="SEE ASSETS" href="#/summary" />
      </Wizard>

      <Footer />
    </PageContainer>
  );
};
