import React from 'react';
import { ISVG } from './../types';
const SummaryPrivatePensionSVG = ({ width, height }: ISVG) => (
  <svg id="Group_305" data-name="Group 305" xmlns="http://www.w3.org/2000/svg" 
    width={width || "87.68"}
    height={height || "85.731"} 
    viewBox="0 0 87.68 85.731"
  >
    <path id="Path_3502_1_" d="M29.789,80.925,39.86,86.074s11.434.151,16.961-.681,8.859-9.465-.3-10.9c-9.162-1.514-12.872-.833-12.872-.833s-9.086-6.209-13.478-8.784-10.9-3.559-18.1.3A96.344,96.344,0,0,1,2,70.249l10.147,22.11s2.574-1.136,5.679-2.574,15.674,4.24,22.186,7.193,10.6,1.439,14.69-1.287c4.089-2.8,21.353-13.857,27.865-18.249s1.969-12.872-6.436-7.269c-8.481,5.6-13.857,8.556-13.857,8.556" transform="translate(0 -14.714)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
    <path id="Path_3503_1_" d="M94.021,71.545c-3.029-4.316-5.452-4.165-10.828-.833a54.74,54.74,0,0,0-7.193,5" transform="translate(-17.967 -16.086)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
    <path id="Path_3504_1_" d="M79.756,69.618c-3.407-3.937-6.133-2.726-11.055.909-5.149,3.786-5.3,3.937-5.3,3.937" transform="translate(-14.908 -15.825)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
    <path id="Path_3676_1_" d="M51.654,24.9A13.554,13.554,0,1,1,38.1,38.454,13.54,13.54,0,0,1,51.654,24.9Z" transform="translate(-8.765 -5.56)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
    <circle id="Ellipse_65_1_" cx="13.554" cy="13.554" r="13.554" strokeWidth="4" transform="translate(50.461 2)" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" fill="none"/>
    <path id="Path_948" data-name="Path 948" d="M86.949,23.145c0,.681-.379.984-1.136.984H77.636c-.757,0-1.136-.3-1.136-.984s.379-.984,1.136-.984h.681V18.148h-.984a.59.59,0,0,1-.53-.227.757.757,0,0,1,.53-1.287h.984V14.286a3.488,3.488,0,0,1,1.136-2.726,4.332,4.332,0,0,1,3.029-1.06,7.434,7.434,0,0,1,2.2.379,5.128,5.128,0,0,1,1.893,1.06c.151.151.3.227.379.379a1.123,1.123,0,0,1,.076.53.926.926,0,0,1-.227.681.823.823,0,0,1-.984.227c-.151-.076-.227-.151-.379-.227A5.8,5.8,0,0,0,84,12.7a2.97,2.97,0,0,0-1.363-.3,1.56,1.56,0,0,0-1.287.53,2.05,2.05,0,0,0-.454,1.439v2.2h2.65a.86.86,0,0,1,.606.227.732.732,0,0,1,0,1.06.86.86,0,0,1-.606.227h-2.65v4.013h5.073A1.018,1.018,0,0,1,86.949,23.145Z" transform="translate(-18.088 -2.064)" fill="#ffffff"/>
    <path id="Path_949" data-name="Path 949" d="M59.249,45.545c0,.681-.379.984-1.136.984H49.936c-.757,0-1.136-.3-1.136-.984s.379-.984,1.136-.984h.681V40.548h-.984a.59.59,0,0,1-.53-.227.757.757,0,0,1,.53-1.287h.984V36.686a3.488,3.488,0,0,1,1.136-2.726,4.332,4.332,0,0,1,3.029-1.06,7.434,7.434,0,0,1,2.2.379,5.128,5.128,0,0,1,1.893,1.06c.151.151.3.227.379.379a1.123,1.123,0,0,1,.076.53.926.926,0,0,1-.227.681.823.823,0,0,1-.984.227c-.151-.076-.227-.151-.379-.227A5.8,5.8,0,0,0,56.3,35.1a2.97,2.97,0,0,0-1.363-.3,1.56,1.56,0,0,0-1.287.53,2.05,2.05,0,0,0-.454,1.439v2.272h2.65a.86.86,0,0,1,.606.227.732.732,0,0,1,0,1.06.86.86,0,0,1-.606.227h-2.65v4.013h4.846C58.8,44.561,59.249,44.864,59.249,45.545Z" transform="translate(-11.363 -7.502)" fill="#ffffff"/>
  </svg>
);
export default SummaryPrivatePensionSVG;

