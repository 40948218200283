import React from 'react';
import styled from 'styled-components';
import { toPounds } from './../../services/Formats';
import { IChartAxisY } from './types';

const ChartAxisWrapper = styled.div`
  height: 445px;
  background: white;
  width: 100px;

  .label {
    position: absolute;
    z-index: 1;
    left: 0px;
    width: 90px;
    border: none;
    text-align: right;
    font-size: 12px;
  }
`;

export const ChartAxisY = (params: IChartAxisY) => {
  const { height, maxValue } = params;
  // increase step by 500 until number of division is less than 500
  let step = 0;
  //let divs = parseInt( maxValue / step, 10);
  let divs = Math.floor(maxValue / step);
  do {
    step += 500
    //divs = parseInt( maxValue / step, 10);
    divs = Math.floor(maxValue / step);
  } while (divs > 12)
  const dy = height / divs;
  return (
    <ChartAxisWrapper>
      {[...Array(divs + 1).keys()].map(n => (
         <div key={n} className="label" style={{ top: dy * n - 7 }}>
           {toPounds(maxValue - n * step)}
         </div>
      ))}
    </ChartAxisWrapper>
  );
}
