import React from 'react';
import TextInput from './TextInput';

export const PoundsInput = (props: any) => (
  <TextInput prefixes="&pound;" type='currency' {...props} style={{ textAlign: 'left' }}/>
);

export const SmallTextInput = (props: any) => (
  <div style={{ width: 50 }}>
    <TextInput style={{ width: 48, padding: '0px', textAlign: 'center' }}  {...props} />
  </div>
);
