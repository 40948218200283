import React from 'react';
import styled from 'styled-components';
import { ICheckbox } from './types';

const Wrapper = styled.div`
  > button {
    outline: none;
    background: none;
    width: 20px;
    height: 20px;
    border: none;
    cursor: pointer;
  }
  > button.disabled {
    cursor: not-allowed !important;
    pointer-events: all !important;
  }
  svg {
    border-radius: 5px;
  }
`;

export const Checkbox = (params: ICheckbox) => {
  const { color, value, disabled, onChange, strokeWidth = 1, strokeOpacity = 0.5, borderRadius = 5 } = params;
  const cl = (!!value) ? 'active' : '';
  const cld = disabled ? 'disabled'  : '';
  const styleButton = { stroke: color, strokeWidth, strokeOpacity };
  return (
    <Wrapper className='checkbox'>
      <button className={cl+' '+cld} onClick={() => { if (!disabled) { onChange(!value) }}}>
        <svg width='20' height='20' style={{ borderRadius }}>
          <rect x='0' y='0' width='20' height='20' fill='white' style={styleButton} />
          {value ? (
            <polygon fill={color} points='7.6,11.6 4.4,8.4 2,10.8 7.6,16.4 18,1 15.6,3.6'></polygon>
          ) : false}
        </svg>
      </button>
    </Wrapper>
  );
}

