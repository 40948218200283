import { connect } from 'react-redux';
import { YourDetails } from './../components/YourDetails';

const mapStateToProps = (state: any) => (state);
const mapDispatchToProps = (dispatch: any) => ({
  onChangeInput: (field: any, value: any) => {
    dispatch({ type: 'UPDATE_INPUT', payload: { field, value } });
  },
  onChangePartner: (field: any, value: any) => {
    dispatch({ type: 'UPDATE_PARTNER', payload: { field, value } });
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(YourDetails);
