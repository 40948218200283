export const getRoot = () => process.env.REACT_APP_API_URL || 'http://localhost:4009';

const getRequestHeaders = (json: boolean = true) => {
  const reqHeaders = new Headers();
  if (json) {
    reqHeaders.append('Accept', 'application/json');
    reqHeaders.append('Content-Type', 'application/json');
  }
  return reqHeaders;
};

const getPayloadParams = (object: any) => ({
  mode: 'cors',
  redirect: 'follow',
  headers: getRequestHeaders(),
  body: JSON.stringify(object)
});


export const fetchRequest = (req: any) => {
  return (
    fetch(req)
    .then(async (response) => {
      const status = response.status;
      const json = await response.json();
      if (status !== 200 && status !== 201) {
        const errors = {
          errors: {
            message: `${status} ${response.statusText}${json.error && json.error.message ? '. ' + json.error.message : ''}`
          },
        };
        if (json.error._message) {
          console.error(json.error._message);
        }
        return errors;
      }
      return json;
    })
    .then((json) => {
      if (json.errors && json.errors.length) throw json.errors;
      return json;
    })
  );
};

export const ApiRequest = {
  fetchOne: (url: string) => {
    const req = new Request(`${getRoot()}/${url}`, { method: 'get', mode: 'cors', headers: getRequestHeaders() });
    return fetchRequest(req);
  },
  submitOne: (url: string, object: any) => {
    const reqParams = Object.assign({ method: 'POST' }, getPayloadParams(object));
    return fetchRequest(new Request(`${getRoot()}/${url}`, reqParams));
  },
};

export const sendManagerNotification = (data: any) => ApiRequest.submitOne('index.php', data);



