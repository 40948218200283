import styled  from 'styled-components';

const boxShadow = 'box-shadow: 0px 0px 3px 3px #f4f4f4;'

export const Panel = styled.div`
  padding: 20px;
  ${boxShadow}
`;

export const FourColumns = styled.div`
  display: flex;
  > div { width: 25%; text-align: center; }
`;

export const TwoColumns = styled.div`
  display: flex;
  .left { width: 48%; margin-right: 2%; padding: 20px; ${boxShadow} }
  .right { width: 48%; margin-left: 2%; padding: 20px; ${boxShadow} }
`;

export const TwoColumns2 = styled.div`
  display: flex;
  .left { width: 50%; padding: 20px; }
  .right { width: 50%; padding: 20px; }
`;

export const WithSidebar = styled.div`
  display: flex;
  .sidebar { width: 230px; margin-right: 0px; }
  .content { flex: 1; }
`;

export const ThreeColumns = styled.div`
  display: flex;
  > div { width: 33.3%; text-align: center; }
`;

export const InfoBigValue = styled.div`
  font-size: 23px;
  color: #F35C28;
  text-align: center;
  font-weight: 700;
`;
export const InfoLabel = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  color: #F35C28;
`;
export const InfoLabel2 = styled.div`
  font-size: 19px;
  font-weight: 600;
  text-align: center;
  color: #F35C28;
`;

export const InfoTinyLabel = styled.div`
  font-size: 12px;
  font-weight: 100;
  text-align: center;
  line-height: 12px;
  margin-top: 5px;
`;
export const InfoTinyLabel2 = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  line-height: 14px;
  margin-top: 5px;
`;

export const InfoIcon = styled.img`
  display: block;
  margin: 10px auto;
  width: 100px;
  height: 100px;
`;

export const InfoIcon2 = styled.img`
  display: block;
  margin: 10px auto;
  width: 60px;
`;

export const FormRow = styled.div`
  padding-bottom: 10px;
  display: flex;
  align-items: center;
`;

export const RowLabel = styled.div`
  width: 150px;
`;
export const RowLabel2 = styled.div`
  width: 50%;
`;
export const RowContent = styled.div`
  flex: 1;
`;

export const CheckboxRow = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 40px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;

  .checkbox { position: absolute; top: 18px; left: 5px; }
  .title { font-size: 11px; font-weight: bold; color: white; }
  .note { font-size: 10px; color: white; }

  img { width: 28%; }
  .text { width: 66%; }
`;
