import React, { useEffect } from 'react';
import { CenteredHeader, PageContainer, Header, Footer, Wizard, Back, Next } from './Layout';
import { FourColumns, InfoIcon, InfoBigValue, InfoLabel2, InfoTinyLabel2, Panel } from './Grid';
import { PastFutureArrow } from './Form/PastFutureArrow';
import { IHowLongMightYourRetirementBe } from './types';
import { takeScreenshot } from './../services/Screenshots';
import { triggerResize } from './../services/screen';

const linkLifeExpectancy = 'https://www.ons.gov.uk/peoplepopulationandcommunity/healthandsocialcare/healthandlifeexpectancies/articles/whatismylifeexpectancyandhowmightitchange/2017-12-01';

export const HowLongMightYourRetirementBe = (params: IHowLongMightYourRetirementBe) => {
  const { keyPartnerships, saveScreenshot } = params;
  const { output, input } = keyPartnerships;
  const { avgRetirement } = output;
  const { withPartner } = input;
  const { retAge, avgLifeExpectancy, statePensionAge, years } = avgRetirement;

  const retiredAfterStatePension = retAge > statePensionAge;
  
  const elementId = 'past_future_arrow';
  useEffect(() => {
    triggerResize('how-long');
    takeScreenshot(elementId).then(dataURL => {
      saveScreenshot(elementId, dataURL);
    });
  }, [saveScreenshot]);

  const StatePensionBlock = (
    <div>
      <InfoIcon src="images/infographics/howlong2.svg" alt="" />
      <InfoLabel2>State pension age</InfoLabel2>
      <InfoTinyLabel2>
        {withPartner ? (
          <div>If a couple, this is the age of<br />the person who is predicted<br />to start receiving state pension first.</div>
        ) : (
          retiredAfterStatePension ? 
          <div>This is the age when your<br />client would have started<br />receiving state pension unless<br />they deferred their claim.</div>
          :
          <div>This is the age when your<br />client will start receiving<br />their State Pension.</div>
        )}
      </InfoTinyLabel2>
    </div>
  );
  const EstimatedRetirementBlock = (
    <div>
      <InfoIcon src="images/infographics/howlong1.svg" alt="" />
      <InfoLabel2>Estimated retirement age</InfoLabel2>
      <InfoTinyLabel2>
        {withPartner ? (
          <div>If a couple, this is the age of<br />the person who is predicted<br />to retire first.</div>
        ) : (
          <div>This is the age when your<br />client would retire.</div>
        )}
      </InfoTinyLabel2>
    </div>
  );

  return (
    <PageContainer>
      <Header title="Your client's retirement scenario" />
      <Panel>
        <CenteredHeader>How long might your client's retirement be?</CenteredHeader>
        
        <div id={elementId}>
          <FourColumns>
            {retiredAfterStatePension ? StatePensionBlock : EstimatedRetirementBlock}
            {retiredAfterStatePension ? EstimatedRetirementBlock : StatePensionBlock}
            <div></div>
            <div>
              <InfoIcon src="images/infographics/howlong3.svg" alt="" />
              <InfoLabel2>Average life expectancy</InfoLabel2>
              <InfoTinyLabel2>
                If a couple, this is the age of<br />the person who is predicted to<br />live the longest.
              </InfoTinyLabel2>
            </div>
          </FourColumns>

          <FourColumns>
            <PastFutureArrow inactive='#888' past='#F35C28' present='#172730' future='#F35C28' years={years} />
          </FourColumns>

          <FourColumns>
            <InfoBigValue>{retiredAfterStatePension ? statePensionAge : retAge}</InfoBigValue>
            <InfoBigValue>{retiredAfterStatePension ? retAge : statePensionAge}</InfoBigValue>
            <div></div>
            <InfoBigValue>{avgLifeExpectancy}</InfoBigValue>
          </FourColumns>
        </div>

        <div style={{ fontSize: 12, textAlign: 'center', marginTop: 40 }}>
          The above result is based on the Office for National Statistics average
          life expectancy &nbsp;
          <a href={linkLifeExpectancy} rel="noopener noreferrer" target="_blank" style={{ color: '#000' }}>data</a>.
        </div>
      </Panel>

      <Wizard>
        <Back label="BACK" href="#/summary" />
        <div className="flex"></div>
        <Next label="NEXT" href="#/desired-monthly-spend-in-retirement" />
      </Wizard>

      <Footer />
    </PageContainer>
  );
};
