import { connect } from 'react-redux';
import { DesiredSpendVsSustainableIncome } from './../components/DesiredSpendVsSustainableIncome';

const mapStateToProps = (state: any) => (state);
const mapDispatchToProps = (dispatch: any) => ({
  onChangeInput: (field: any, value: any) => {
    dispatch({ type: 'UPDATE_INPUT', payload: { field, value } });
  },
  onChangeInclude: (field: any, value: any) => {
    dispatch({ type: 'UPDATE_INCLUDE', payload: { field, value } });
  },
  saveScreenshot: (screenshotId: string, dataURL: string) => {
    dispatch({ type: 'SAVE_SCREENSHOT', payload: { screenshotId, dataURL } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DesiredSpendVsSustainableIncome);
