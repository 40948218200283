const initialState = {
  width: typeof window === 'object' ? window.innerWidth : 1024,
  height: typeof window === 'object' ? window.innerHeight : 800,
  /* eslint-disable eqeqeq */
  inFrame: (window != window.top),
  container: {},
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case 'SCREEN_RESIZE': {
      if (typeof window === 'object') {
        if (window.parent) {
          window.parent.postMessage({
            origin: window.location.href,
            height: document.querySelector("body").clientHeight,
          }, '*');
        }
        return { ...state, width: window.innerWidth, height: window.innerHeight }
      }
      break;
    }
    case 'SET_CONTAINER_SIZE': {
      const { container } = action.payload;
      return { ...state, container: { ...container } };
    }
    default:
  }
  return state
};
