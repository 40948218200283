import domToImage from 'dom-to-image';
// import html2canvas from 'html2canvas';

export const takeScreenshot = async (elementId: string) => {
  const element = document.getElementById(elementId);
  const filter = (node: any) => {
    return !(node.className && typeof node.className === 'string' && (
      node.className.includes('infotip') || node.className.includes('find-out')
    ));
  };
  //console.log({ elementId, svg: await domToImage.toSvg(element, { filter }) });
  const dataURL = await domToImage.toPng(element, { filter });
  return dataURL;
};

// export const takeScreenshot = async (elementId: string) => {
//   const element = document.getElementById(elementId);
//   const canvas = await html2canvas(element);
//   const dataURL = canvas.toDataURL('image/png');
//   return dataURL;
// };
