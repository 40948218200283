import { connect } from 'react-redux';
import { DesiredMonthlySpend} from './../components/DesiredMonthlySpend';

const mapStateToProps = (state: any) => (state);
const mapDispatchToProps = (dispatch: any) => ({
  onChangeMonthlySpend: (field: string, value: any) => {
    dispatch({ type: 'UPDATE_MONTHLY_SPEND', payload: { field, value } });
  },
  saveScreenshot: (screenshotId: string, dataURL: string) => {
    dispatch({ type: 'SAVE_SCREENSHOT', payload: { screenshotId, dataURL } });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(DesiredMonthlySpend);
