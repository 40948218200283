const emptyPNG = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8/x8AAwMCAO+ip1sAAAAASUVORK5CYII=';
interface IState { [index :string]: string | boolean };
const initialState: IState = {
  summary: emptyPNG,
  past_future_arrow: emptyPNG,
  desired_spend: emptyPNG,
  chart_without_mortgage: emptyPNG,
  chart_with_mortgage: emptyPNG,
  // assets_without_mortgage: emptyPNG,
  // assets_with_mortgage: emptyPNG,
  // savings_without_mortgage: emptyPNG,
  // private_pension_without_mortgage: emptyPNG,
  // state_pension_without_mortgage: emptyPNG,
  savings: emptyPNG,
  private_pension: emptyPNG,
  state_pension: emptyPNG,
  lifetime_mortgage_with_mortgage: emptyPNG,
  all: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case 'SAVE_SCREENSHOT': {
      const { screenshotId, dataURL } = action.payload;
      const _state: IState = { ...state, [screenshotId]: dataURL };
      const all = Object.keys(_state).reduce((_all, key) => {
        if (_all && _state[key] === emptyPNG) {
          _all = false;
        }
        return _all;
      }, true);
      return { ..._state, all };
    }
    default:
  }
  return state
};
