import React from 'react';
import styled from 'styled-components';
import { IGender } from './types';

const Wrapper = styled.div`
  > button {
    outline: none;
    width: 39px;
    height: 39px;
    border: none;
    cursor: pointer;
  }
  .female { background: url(images/female.svg) no-repeat; }
  .male { background: url(images/male.svg) no-repeat; }
  .female.active { background: url(images/female-active.svg) no-repeat; }
  .male.active { background: url(images/male-active.svg) no-repeat; }
`;

export const Gender = (params: IGender) => {
  const { value, onChange } = params;
  const maleClass = `${value === 'M' ? 'active': ''} male`;
  const femaleClass = `${value === 'F' ? 'active': ''} female`;
  return (
    <Wrapper>
      <button className={maleClass} onClick={() => onChange('M')}>
      </button>
      <button className={femaleClass} onClick={() => onChange('F')}>
      </button>
    </Wrapper>
  );
}

