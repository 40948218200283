import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
  position: relative;
  display: inline-block;

  .tip {
    text-align: left;
     display: none;
     width: 250px;

     position: absolute; top: -10px; left: 40px;
     z-index: 1000;
     background: #6d7085;
     padding: 15px 20px;
     white-space: normal;
     line-height: 1.5em;
     color: #f6f6f7;
     box-shadow: 0px 0px 5px #cdced6;
     border-radius: 5px;

     font-size: 12px;
     font-weight: normal;

     a { color: black !important; }
  }
  .tip.right {
     left: auto;
     right: 30px;
  }
  &:hover .tip {
    display: block;
  }
  .tip:after {
    content: " ";
    position: absolute;
    top: 20px;
    right: 100%;
    margin-top: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent #6d7085 transparent transparent;
  }
  .tip.right:after {
    content: " ";
    position: absolute;
    top: 20px;
    left: 100%;
    margin-top: -5px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent transparent #6d7085;
  }

  @media(max-width: 620px) {
    .tip {
      width: 260px;
      left: -75px;
      top: 30px;
    }
    .tip.right {
      left: auto;
      right: -100px;
    }
    .tip:after {
      content: " ";
      position: absolute;
      top: -13px;
      bottom: 100%;
      left: 75px;
      right: 205px;
      margin-left: 7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #6d7085 transparent!important;
    }
    .tip.right:after {
      content: " ";
      position: absolute;
      top: -13px;
      bottom: 100%;
      left: 175px;
      right: 205px;
      margin-left: 7px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent transparent #6d7085 transparent!important;
    }
  }
`;

export class InfoTip extends React.Component {
	public props: any;
	public value: any;
  public orientation: any;

  render(){
    const { value, orientation = '' } = this.props;
    return (
      <Wrap className="infotip">
        &nbsp;
        <svg style={{ width: 20, height: 20 }} viewBox="0 0 100 100">
          <path fill="#6d7085" d="M50.433,0.892c-27.119,0-49.102,21.983-49.102,49.102s21.983,49.103,49.102,49.103s49.101-21.984,49.101-49.103S77.552,0.892,50.433,0.892z M59,79.031C59,83.433,55.194,87,50.5,87S42,83.433,42,79.031V42.469c0-4.401,3.806-7.969,8.5-7.969s8.5,3.568,8.5,7.969V79.031z M50.433,31.214c-5.048,0-9.141-4.092-9.141-9.142c0-5.049,4.092-9.141,9.141-9.141c5.05,0,9.142,4.092,9.142,9.141C59.574,27.122,55.482,31.214,50.433,31.214z"/>
        </svg>
        <div className={`tip ${orientation}`}>{value}</div>
      </Wrap>
    );
  }

}
