import React from 'react';
import { ISVG } from '../types';
const SummaryStatePensionSVG = ({ width, height }: ISVG) => (
  <svg id="Group_304" data-name="Group 304" xmlns="http://www.w3.org/2000/svg" 
    width={width || "94.572"} 
    height={height || "94.666"}
    viewBox="0 0 94.572 94.666"
  >
    <g id="Group_303" data-name="Group 303" transform="translate(2 2)">
      <rect id="Rectangle_847" data-name="Rectangle 847" width="77.192" height="53.707" transform="translate(6.737 36.959)" strokeWidth="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <rect id="Rectangle_848" data-name="Rectangle 848" width="63.719" height="10.105" transform="translate(13.38 26.854)" strokeWidth="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <line id="Line_20" data-name="Line 20" y2="26.854" transform="translate(45.286)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      <path id="Path_946" data-name="Path 946" d="M50.4,5.6h8.421V15.612H50.4" transform="translate(-5.114 -2.232)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      <path id="Path_947" data-name="Path 947" d="M59.4,9.2H69.412l-3.275,5.053,3.275,4.959H59.4Z" transform="translate(-5.693 -2.463)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      <line id="Line_21" data-name="Line 21" x2="90.572" transform="translate(0 90.572)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4"/>
      <rect id="Rectangle_849" data-name="Rectangle 849" width="10.105" height="36.959" transform="translate(40.234 53.707)" strokeWidth="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <rect id="Rectangle_850" data-name="Rectangle 850" width="10.105" height="36.959" transform="translate(16.748 53.707)" strokeWidth="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
      <rect id="Rectangle_851" data-name="Rectangle 851" width="10.105" height="36.959" transform="translate(63.719 53.707)" strokeWidth="4" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" fill="none"/>
    </g>
  </svg>
);
export default SummaryStatePensionSVG;

