import React from 'react';
import { ISVG } from './../types';
const SummarySavingsSVG = ({ width, height }: ISVG) => (
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    width={width || "99.211"}
    height={height || "94.766"}
    viewBox="0 0 99.211 94.766"
  >
    <g id="Group_302" data-name="Group 302" transform="translate(0.024)">
      <path id="Path_942" data-name="Path 942" d="M31.672,40.052l2.447-.316a23.412,23.412,0,0,1,3.315-.237h14.6a26.908,26.908,0,0,1,17.68,47.2v11.05a2.914,2.914,0,0,1-2.92,2.92H56.613a2.914,2.914,0,0,1-2.92-2.92v-4.42h-.316c-.395,0-.868.079-1.263.079h-14.6a6.79,6.79,0,0,1-1.263-.079h-.316v4.42a2.95,2.95,0,0,1-2.841,2.92H22.674a2.95,2.95,0,0,1-2.92-2.841V86.7a26.633,26.633,0,0,1-5.92-7.34v-.079l-.079-.079c-1.579-2.131-5.367-3.078-7.893-3.71a14.233,14.233,0,0,0-1.5-.395l-1.342-.474-.474-1.342c-.868-2.526-.71-9.708.237-12.786.868-3,3.394-3.631,5.841-4.262L9.493,56c1.894-.552,4.578-3.473,6.709-6l1.5-1.815" transform="translate(0 -7.902)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
      <path id="Path_943" data-name="Path 943" d="M34.043,33a75.906,75.906,0,0,1,.079,20.284A76.672,76.672,0,0,1,16.6,43.813,76.323,76.323,0,0,1,34.043,33Z" transform="translate(-3.081 -6.533)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
      <path id="Path_944" data-name="Path 944" d="M98.4,60.547a20.8,20.8,0,0,0,11.129-1.815c1.973-.947,3.946-2.21,4.893-4.262a5.966,5.966,0,0,0-1.5-6.63,5.817,5.817,0,0,0-6.788-.474,6.707,6.707,0,0,0-2.526,7.419,9.754,9.754,0,0,0,5.525,5.92,17.017,17.017,0,0,0,8.208,1.026" transform="translate(-20.319 -9.376)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
      <path id="Path_945" data-name="Path 945" d="M49.4,48.656C57.214,48.5,69.369,47.394,76,54.1" transform="translate(-9.993 -9.798)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
      <path id="Path_3508_2_" d="M24.657,61.8a1.657,1.657,0,1,0,1.657,1.657h0A1.72,1.72,0,0,0,24.657,61.8Z" transform="translate(-4.43 -12.602)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
      <path id="Path_3487_1_" d="M59.445,2A9.945,9.945,0,1,1,49.5,11.945,9.954,9.954,0,0,1,59.445,2h0" transform="translate(-10.014)" fill="none" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="4"/>
    </g>
  </svg>
);
export default SummarySavingsSVG;