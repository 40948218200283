import { toPounds } from './Formats';

interface IInput {
  [index: string]: any;
}

interface IFields {
  [index: string]: {
    label: string;
    parser?: any;
  };
}

const formatDate = (date: Date) => `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
const formatInt = (num: number) => num.toString();
const formatGender = (g: string) => g === 'M' ? 'Male' : (g === 'F' ? 'Female' : g);
const formatBool = (val: boolean) => val ? 'Yes' : 'No';
const formatPercentage = (val: number) => `${val.toFixed(2)}%`;

const fields: IFields = {

  // Client's details:
  age: { 
    label: 'Age',
    parser: formatInt,
  },
  birthdate: {
    label: 'Date of birth',
    parser: formatDate,
  },
  gender: {
    label: 'Gender',
    parser: formatGender,
  },
  retAge: {
    label: 'Retirement age',
    parser: formatInt,
  },
  statePension: {
    label: 'State pension',
    parser: formatBool
  },
  statePensionAge: {
    label: 'State pension age',
    parser: formatInt,
  },
  statePensionAmount: {
    label: 'State pension amount per month',
    parser: toPounds,
  },
  lifeExpectancy: {
    label: 'Life expectancy',
  },

  // Partner's details:
  partnerAge: {
    label: 'Partner age',
    parser: formatInt,
  },
  partnerBirthdate: {
    label: 'Partner birthdate',
    parser: formatDate
  },
  partnerGender: {
    label: 'Partner gender',
    parser: formatGender,
  },
  partnerRetAge: {
    label: 'Partner retirement age',
    parser: formatInt,
  },
  partnerStatePension: {
    label: 'Partner state pension',
    parser: formatBool,
  },
  partnerStatePensionAge: {
    label: 'Partner state pension age',
    parser: formatInt,
  },
  partnerStatePensionAmount: {
    label: 'Partner state pension amount',
    parser: toPounds,
  },
  partnerlifeExpectancy: {
    label: 'Partner life expectancy',
  },

  // Income:
  saving: {
    label: 'Savings/investments',
    parser: toPounds,
  },
  homeVal: {
    label: 'Current home value',
    parser: toPounds,
  },
  percentHouse: {
    label: 'HPI',
    parser: formatPercentage,
  },
  mortgageVal: {
    label: 'Mortgage value at retirement',
    parser: toPounds,
  },
  outstandingDebt: {
    label: 'Outstanding debt',
    parser: toPounds,
  },

  // Spendings:
  monthlySpendCouncilTax: {
    label: 'Council tax & house maintenance',
    parser: toPounds,
  },
  monthlySpendUtilities: {
    label: 'Utilities',
    parser: toPounds,
  }, 
  monthlySpendPhone: {
    label: 'Phone & broadband',
    parser: toPounds,
  },
  monthlySpendFoodDrink: {
    label: 'Monthly food shop',
    parser: toPounds,
  },
  monthlySpendEating: {
    label: 'Eating & drinking out',
    parser: toPounds,
  },
  monthlySpendClothing: {
    label: 'Clothing & footwear',
    parser: toPounds,
  },
  monthlySpendTransport: {
    label: 'Transport',
    parser: toPounds,
  },
  monthlySpendLeisure: {
    label: 'Leisure & hobbies',
    parser: toPounds,
  },
  monthlySpendHoliday: {
    label: 'Holidays',
    parser: toPounds,
  },
  monthlySpendMedical: {
    label: 'Medical & personal care',
    parser: toPounds,
  },
  monthlySpendOther: {
    label: 'Other',
    parser: toPounds,
  },
};

export const parse = (input: IInput) => {
  const _input: IInput = {};
  for (const key in fields) {
    const field = fields[key];
    const { label, parser } = field;
    const prefix = key.indexOf('partner') === 0 ? 'partner' : (key.indexOf('monthlySpend') === 0 ? 'monthlySpend' : '');
    let _value;
    if (prefix) {
      const __key = key.replace(prefix, '');
      const _key = `${__key.charAt(0).toLowerCase()}${__key.substring(1)}`;
      _value = input[prefix][_key];
    } else {
      _value = input[key];
    }
    if (_value && !skipField(key, input)) {
      const value = typeof parser === 'function' ? parser(_value) : _value;
      _input[label] = value;
    }
  }
  return _input; 
};

const skipField = (field: string, input: IInput) => {
  if (
    (['statePensionAge', 'statePensionAmount'].includes(field) && !input.statePension) //person's state pension
    || (['partnerStatePensionAge', 'partnerStatePensionAge'].includes(field) && !input.statePension) // partner's state pension
    || (field.indexOf('partner') === 0 && !input.withPartner)  //no partner
  ) {
      return true;
  }
  return false;
};