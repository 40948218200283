export const ages = [
  {
    // 06/04/1950 to 05/03/1951 60 years
    minDateOfBirth: '1950-04-06',
    maxDateOfBirth: '1951-03-05',
    gender: 'F',
    statePensionAge: 60,
  },
  {
    //3-6-1951	61 years
    minDateOfBirth: '1951-03-06',
    maxDateOfBirth: '1951-03-06',
    gender: 'F',
    statePensionAge: 61,
  },
  {
    // 07/03/1951 to 05/04/1951	60 years
    minDateOfBirth: '1951-03-07',
    maxDateOfBirth: '1951-04-05',
    gender: 'F',
    statePensionAge: 60,
  },
  {
    // 06/04/1951 to 05/03/1952	61 years
    minDateOfBirth: '1951-04-06',
    maxDateOfBirth: '1952-03-05',
    gender: 'F',
    statePensionAge: 61,
  },
  {
    // 3-6-1952	62 years
    minDateOfBirth: '1952-03-06',
    maxDateOfBirth: '1952-03-06',
    gender: 'F',
    statePensionAge: 62,
  },
  {
    // 07/03/1952 to 05/04/1952	61 years
    minDateOfBirth: '1952-03-07',
    maxDateOfBirth: '1952-04-05',
    gender: 'F',
    statePensionAge: 61,
  },
  {
    // 06/04/1952 to 05/03/1953	62 years
    minDateOfBirth: '1952-04-06',
    maxDateOfBirth: '1953-03-05',
    gender: 'F',
    statePensionAge: 62,
  },
  {
    // 3-6-1953	63 years
    minDateOfBirth: '1953-03-06',
    maxDateOfBirth: '1953-03-06',
    gender: 'F',
    statePensionAge: 63,
  },
  {
    // 07/03/1953 to 05/04/1953	62 years
    minDateOfBirth: '1953-03-07',
    maxDateOfBirth: '1953-04-05',
    gender: 'F',
    statePensionAge: 62,
  },
  {
    // 06/04/1953 to 05/07/1953	63 years
    minDateOfBirth: '1953-04-06',
    maxDateOfBirth: '1953-07-05',
    gender: 'F',
    statePensionAge: 63,
  },
  {
    // 7-6-1953	64 years
    minDateOfBirth: '1953-07-06',
    maxDateOfBirth: '1953-07-06',
    gender: 'F',
    statePensionAge: 64,
  },
  {
    // 07/07/1953 to 05/08/1953	63 years
    minDateOfBirth: '1953-07-07',
    maxDateOfBirth: '1953-08-05',
    gender: 'F',
    statePensionAge: 63,
  },
  {
    // 06/08/1953 to 05/11/1953	64 years
    minDateOfBirth: '1953-08-06',
    maxDateOfBirth: '1953-11-05',
    gender: 'F',
    statePensionAge: 64,
  },
  {
    // 11-6-1953	65 years
    minDateOfBirth: '1953-11-06',
    maxDateOfBirth: '1951-11-06',
    gender: 'F',
    statePensionAge: 65,
  },
  {
    // 07/11/1953 to 05/12/1953	64 years
    minDateOfBirth: '1953-11-07',
    maxDateOfBirth: '1953-12-05',
    gender: 'F',
    statePensionAge: 64,
  },
  {
    //06/04/1950 to 05/12/1953	65 years
    minDateOfBirth: '1950-04-06',
    maxDateOfBirth: '1953-12-05',
    gender: 'M',
    statePensionAge: 65,
  },
  {
    // 06/12/1953 to 05/09/1954	65 years
    minDateOfBirth: '1953-12-06',
    maxDateOfBirth: '1953-09-05',
    gender: 'MF',
    statePensionAge: 65,
  },
  {
    // 9-6-1954	66 years
    minDateOfBirth: '1954-09-06',
    maxDateOfBirth: '1954-09-06',
    gender: 'MF',
    statePensionAge: 66,
  },
  {
    // 07/09/1954 to 05/10/1954	65 years
    minDateOfBirth: '1954-09-07',
    maxDateOfBirth: '1954-10-05',
    gender: 'MF',
    statePensionAge: 65,
  },
  {
    // 06/10/1954 to 05/03/1961	66 years
    minDateOfBirth: '1954-10-06',
    maxDateOfBirth: '1961-03-05',
    gender: 'MF',
    statePensionAge: 66,
  },
  {
    // 06/03/1961 to 05/03/1978	67 years
    minDateOfBirth: '1961-03-06',
    maxDateOfBirth: '1978-03-05',
    gender: 'MF',
    statePensionAge: 67,
  },
  {
    // 3-6-1978	68 years
    minDateOfBirth: '1978-03-06',
    maxDateOfBirth: '1978-03-06',
    gender: 'MF',
    statePensionAge: 68,
  },
  {
    // 07/03/1978 to 05/04/1978	67 years
    minDateOfBirth: '1978-03-07',
    maxDateOfBirth: '1978-04-05',
    gender: 'MF',
    statePensionAge: 67,
  },
  {
    // 06/04/1978 to now	68 years
    minDateOfBirth: '1978-04-06',
    maxDateOfBirth: (new Date()).toISOString().split('T')[0],
    gender: 'MF',
    statePensionAge: 68,
  },
];

export const findStatePensionAge = (dateOfBirth: Date, gender: string) => {
  const sPA = ages.find(_sPA => {
    const { minDateOfBirth, maxDateOfBirth, gender: _gender } = _sPA;
    // console.log({
    //   dateOfBirth, gender, minDateOfBirth, maxDateOfBirth, gender: _gender, statePensionAge,
    //   min: new Date(`${minDateOfBirth}T00:00:00`) <= dateOfBirth,
    //   max: dateOfBirth <= new Date(`${maxDateOfBirth}T23:59:59`),
    //   gend: _gender.includes(gender),
    // });
    return (new Date(`${minDateOfBirth}T00:00:00`) <= dateOfBirth) && 
            (dateOfBirth <= new Date(`${maxDateOfBirth}T23:59:59`)) &&
            (_gender.includes(gender));
  });
  if (sPA) {
    return sPA.statePensionAge; 
  }
  return 0;
};