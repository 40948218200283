import { combineReducers } from 'redux';
import { reducer as responsive } from 'react-responsive-redux';
import screen from './screen';
import keyPartnerships from './keyPartnerships';
import screenshots from './screenshots';

const reducers = {
  responsive, screen, keyPartnerships, screenshots
};
const rootReducer = combineReducers(reducers);
export default rootReducer;
