import React from 'react';
import { ISVG } from '../types';
const SummaryLifetimeMortgageOrangeSVG = ({ width, height }: ISVG) => (
  <svg xmlns="http://www.w3.org/2000/svg" 
    width={width || "59.009"} 
    height={height || "51.6"} 
    viewBox="0 0 59.009 51.6"
  >
    <g id="Group_361" data-name="Group 361" transform="translate(-1 -1)">
      <g id="Group_343" data-name="Group 343" transform="translate(2 2)">
        <path id="Path_989" data-name="Path 989" d="M51.137,51.6V28.292h7.872l-10.445-9.57V4.881H41.258v7.152L30.35,2,2,28.292H9.872V51.6Z" transform="translate(-2 -2)" fill="none" stroke="#f25c28" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
      </g>
      <g id="Group_344" data-name="Group 344" transform="translate(21.295 22.581)">
        <circle id="Ellipse_65_2_" cx="9.21" cy="9.21" r="9.21" fill="none" stroke="#f25c28" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="2"/>
        <path id="Path_990" data-name="Path 990" d="M57.1,59.193c0,.463-.257.669-.772.669H50.772c-.515,0-.772-.206-.772-.669s.257-.669.772-.669h.463V55.8h-.669a.4.4,0,0,1-.36-.154.388.388,0,0,1-.206-.36.528.528,0,0,1,.515-.515h.669v-1.6a2.37,2.37,0,0,1,.772-1.852,2.944,2.944,0,0,1,2.058-.72,5.052,5.052,0,0,1,1.492.257,3.485,3.485,0,0,1,1.286.72c.1.1.206.154.257.257a.763.763,0,0,1,.051.36.629.629,0,0,1-.154.463.559.559,0,0,1-.669.154c-.1-.051-.154-.1-.257-.154a3.94,3.94,0,0,0-.978-.566,2.018,2.018,0,0,0-.926-.206,1.06,1.06,0,0,0-.875.36,1.393,1.393,0,0,0-.309.978v1.492h1.8a.584.584,0,0,1,.412.154.5.5,0,0,1,0,.72.584.584,0,0,1-.412.154h-1.8v2.778H56.38C56.843,58.524,57.1,58.729,57.1,59.193Z" transform="translate(-44.598 -46.175)" fill="#f25c28"/>
      </g>
    </g>
  </svg>
);
export default SummaryLifetimeMortgageOrangeSVG;

