import { getYear } from 'date-fns';

export const MALE: any = {
    "40": 86,
    "41": 86,
    "42": 86,
    "43": 85,
    "44": 85,
    "45": 85,
    "46": 85,
    "47": 85,
    "48": 85,
    "49": 85,
    "50": 85,
    "51": 85,
    "52": 85,
    "53": 85,
    "54": 85,
    "55": 85,
    "56": 85,
    "57": 85,
    "58": 85,
    "59": 85,
    "60": 85,
    "61": 85,
    "62": 85,
    "63": 86,
    "64": 86,
    "65": 86,
    "66": 86,
    "67": 86,
    "68": 86,
    "69": 86,
    "70": 86,
    "71": 87,
    "72": 87,
    "73": 87,
    "74": 87,
    "75": 88,
    "76": 88,
    "77": 88,
    "78": 88,
    "79": 89,
    "80": 89,
    "81": 89,
    "82": 90,
    "83": 90,
    "84": 91,
    "85": 91,
    "86": 92,
    "87": 92,
    "88": 93,
    "89": 94,
    "90": 94,
    "91": 95,
    "92": 96,
    "93": 96,
    "94": 97,
    "95": 98
};

export const FEMALE: any = {
    "40": 88,
    "41": 88,
    "42": 88,
    "43": 88,
    "44": 88,
    "45": 88,
    "46": 88,
    "47": 88,
    "48": 88,
    "49": 88,
    "50": 88,
    "51": 88,
    "52": 88,
    "53": 88,
    "54": 88,
    "55": 88,
    "56": 88,
    "57": 88,
    "58": 88,
    "59": 88,
    "60": 88,
    "61": 88,
    "62": 88,
    "63": 88,
    "64": 88,
    "65": 88,
    "66": 88,
    "67": 88,
    "68": 88,
    "69": 88,
    "70": 88,
    "71": 88,
    "72": 89,
    "73": 89,
    "74": 89,
    "75": 89,
    "76": 89,
    "77": 90,
    "78": 90,
    "79": 90,
    "80": 90,
    "81": 91,
    "82": 91,
    "83": 91,
    "84": 92,
    "85": 92,
    "86": 93,
    "87": 93,
    "88": 94,
    "89": 94,
    "90": 95,
    "91": 95,
    "92": 96,
    "93": 97,
    "94": 97,
    "95": 98
};

export const LIFE_EXPECTANCY: any = {
    "1900-01-01T00:00:00.000Z": ["56.4","49.8"],
    "1901-01-01T00:00:00.000Z": ["57","50.4"],
    "1902-01-01T00:00:00.000Z": ["58.6","51.9"],
    "1903-01-01T00:00:00.000Z": ["58.7","52.1"],
    "1904-01-01T00:00:00.000Z": ["58.3","51.8"],
    "1905-01-01T00:00:00.000Z": ["59.6","53"],
    "1906-01-01T00:00:00.000Z": ["59.8","53.2"],
    "1907-01-01T00:00:00.000Z": ["61.1","54.4"],
    "1908-01-01T00:00:00.000Z": ["61.2","54.6"],
    "1909-01-01T00:00:00.000Z": ["62.2","55.7"],
    "1910-01-01T00:00:00.000Z": ["62.3","55.9"],
    "1911-01-01T00:00:00.000Z": ["61.3","54.8"],
    "1912-01-01T00:00:00.000Z": ["63.6","57.1"],
    "1913-01-01T00:00:00.000Z": ["62.8","56.3"],
    "1914-01-01T00:00:00.000Z": ["63.2","56.6"],
    "1915-01-01T00:00:00.000Z": ["64.1","57.5"],
    "1916-01-01T00:00:00.000Z": ["64.9","58.4"],
    "1917-01-01T00:00:00.000Z": ["65.2","58.8"],
    "1918-01-01T00:00:00.000Z": ["66.2","59.3"],
    "1919-01-01T00:00:00.000Z": ["66.8","59.9"],
    "1920-01-01T00:00:00.000Z": ["67.4","60.7"],
    "1921-01-01T00:00:00.000Z": ["67.9","61.2"],
    "1922-01-01T00:00:00.000Z": ["68.8","62.1"],
    "1923-01-01T00:00:00.000Z": ["69.1","62.7"],
    "1924-01-01T00:00:00.000Z": ["69.1","62.6"],
    "1925-01-01T00:00:00.000Z": ["69.8","63.5"],
    "1926-01-01T00:00:00.000Z": ["70.4","64.1"],
    "1927-01-01T00:00:00.000Z": ["70.9","64.9"],
    "1928-01-01T00:00:00.000Z": ["71.2","65.1"],
    "1929-01-01T00:00:00.000Z": ["71.5","65.5"],
    "1930-01-01T00:00:00.000Z": ["73","67"],
    "1931-01-01T00:00:00.000Z": ["73","67"],
    "1932-01-01T00:00:00.000Z": ["73.7","67.8"],
    "1933-01-01T00:00:00.000Z": ["74.3","68.4"],
    "1934-01-01T00:00:00.000Z": ["75.1","69.4"],
    "1935-01-01T00:00:00.000Z": ["75.7","70"],
    "1936-01-01T00:00:00.000Z": ["76.1","70.3"],
    "1937-01-01T00:00:00.000Z": ["76.6","70.9"],
    "1938-01-01T00:00:00.000Z": ["77.3","71.8"],
    "1939-01-01T00:00:00.000Z": ["77.6","72.1"],
    "1940-01-01T00:00:00.000Z": ["77.6","71.9"],
    "1941-01-01T00:00:00.000Z": ["77.9","72.3"],
    "1942-01-01T00:00:00.000Z": ["79","73.6"],
    "1943-01-01T00:00:00.000Z": ["79.3","74.1"],
    "1944-01-01T00:00:00.000Z": ["79.8","74.9"],
    "1945-01-01T00:00:00.000Z": ["79.9","74.8"],
    "1946-01-01T00:00:00.000Z": ["80.7","75.9"],
    "1947-01-01T00:00:00.000Z": ["80.9","76.2"],
    "1948-01-01T00:00:00.000Z": ["81.7","77.1"],
    "1949-01-01T00:00:00.000Z": ["82.1","77.4"],
    "1950-01-01T00:00:00.000Z": ["82.5","77.9"],
    "1951-01-01T00:00:00.000Z": ["82.7","78.1"],
    "1952-01-01T00:00:00.000Z": ["83","78.5"],
    "1953-01-01T00:00:00.000Z": ["83.2","78.7"],
    "1954-01-01T00:00:00.000Z": ["83.4","79"],
    "1955-01-01T00:00:00.000Z": ["83.7","79.2"],
    "1956-01-01T00:00:00.000Z": ["83.9","79.6"],
    "1957-01-01T00:00:00.000Z": ["84","79.8"],
    "1958-01-01T00:00:00.000Z": ["84.3","80.1"],
    "1959-01-01T00:00:00.000Z": ["84.4","80.3"],
    "1960-01-01T00:00:00.000Z": ["84.7","80.5"],
    "1961-01-01T00:00:00.000Z": ["84.9","80.7"],
    "1962-01-01T00:00:00.000Z": ["85","80.8"],
    "1963-01-01T00:00:00.000Z": ["85.2","81"],
    "1964-01-01T00:00:00.000Z": ["85.4","81.3"],
    "1965-01-01T00:00:00.000Z": ["85.6","81.4"],
    "1966-01-01T00:00:00.000Z": ["85.7","81.7"],
    "1967-01-01T00:00:00.000Z": ["85.9","81.9"],
    "1968-01-01T00:00:00.000Z": ["86.1","81.9"],
    "1969-01-01T00:00:00.000Z": ["86.2","82.2"],
    "1970-01-01T00:00:00.000Z": ["86.4","82.3"],
    "1971-01-01T00:00:00.000Z": ["86.6","82.5"],
    "1972-01-01T00:00:00.000Z": ["86.7","82.7"],
    "1973-01-01T00:00:00.000Z": ["86.9","82.9"],
    "1974-01-01T00:00:00.000Z": ["87.1","83.1"],
    "1975-01-01T00:00:00.000Z": ["87.2","83.4"],
    "1976-01-01T00:00:00.000Z": ["87.5","83.7"],
    "1977-01-01T00:00:00.000Z": ["87.7","83.9"],
    "1978-01-01T00:00:00.000Z": ["87.8","84.2"],
    "1979-01-01T00:00:00.000Z": ["88","84.3"],
    "1980-01-01T00:00:00.000Z": ["88.2","84.6"],
    "1981-01-01T00:00:00.000Z": ["88.5","84.8"],
    "1982-01-01T00:00:00.000Z": ["88.6","85"],
    "1983-01-01T00:00:00.000Z": ["88.8","85.2"],
    "1984-01-01T00:00:00.000Z": ["89","85.5"],
    "1985-01-01T00:00:00.000Z": ["89.1","85.7"],
    "1986-01-01T00:00:00.000Z": ["89.3","85.7"],
    "1987-01-01T00:00:00.000Z": ["89.4","85.9"],
    "1988-01-01T00:00:00.000Z": ["89.6","86.1"],
    "1989-01-01T00:00:00.000Z": ["89.7","86.4"],
    "1990-01-01T00:00:00.000Z": ["89.9","86.6"],
    "1991-01-01T00:00:00.000Z": ["90.1","86.8"],
    "1992-01-01T00:00:00.000Z": ["90.3","87"],
    "1993-01-01T00:00:00.000Z": ["90.4","87.2"],
    "1994-01-01T00:00:00.000Z": ["90.6","87.4"],
    "1995-01-01T00:00:00.000Z": ["90.7","87.6"],
    "1996-01-01T00:00:00.000Z": ["90.9","87.7"],
    "1997-01-01T00:00:00.000Z": ["91","87.9"],
    "1998-01-01T00:00:00.000Z": ["91.1","88"],
    "1999-01-01T00:00:00.000Z": ["91.3","88.2"],
    "2000-01-01T00:00:00.000Z": ["91.4","88.4"],
    "2001-01-01T00:00:00.000Z": ["91.6","88.5"],
    "2002-01-01T00:00:00.000Z": ["91.7","88.7"],
    "2003-01-01T00:00:00.000Z": ["91.8","88.8"],
    "2004-01-01T00:00:00.000Z": ["92","89"],
    "2005-01-01T00:00:00.000Z": ["92.1","89.1"],
    "2006-01-01T00:00:00.000Z": ["92.2","89.3"],
    "2007-01-01T00:00:00.000Z": ["92.4","89.4"],
    "2008-01-01T00:00:00.000Z": ["92.5","89.6"],
    "2009-01-01T00:00:00.000Z": ["92.7","89.7"],
    "2010-01-01T00:00:00.000Z": ["92.8","89.9"],
    "2011-01-01T00:00:00.000Z": ["93","90"],
    "2012-01-01T00:00:00.000Z": ["93.1","90.2"],
    "2013-01-01T00:00:00.000Z": ["93.2","90.4"],
    "2014-01-01T00:00:00.000Z": ["93.4","90.5"],
    "2015-01-01T00:00:00.000Z": ["93.5","90.6"],
    "2016-01-01T00:00:00.000Z": ["93.6","90.8"],
};


export interface IAvgLifeExpectancy {
    age?: any,
    gender: string,
    birthdate: any,
};

// export const getAvgLifeExpectancy = (props: IAvgLifeExpectancy) => {
//     const { age, gender } = props;
//     if (age >= 40 && age <= 95) {
//         if (gender === 'M') { return MALE[age]; }
//         if (gender === 'F') { return FEMALE[age]; }
//     }
//     return 88;
// };

export const getAvgLifeExpectancy = (props: IAvgLifeExpectancy) => {
    const { birthdate, gender } = props;
    const birthdateISO = (new Date(Date.UTC(getYear(birthdate), 0, 1, 0, 0, 0)).toISOString());
    const index = gender === 'F' ? 0 : 1;
    const expectancy = LIFE_EXPECTANCY[birthdateISO][index];
    return expectancy || 88;
};