import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { Provider } from 'react-redux';
import { configure } from './store';
import Debounced from './services/Debounced';
import { triggerResize } from './services/screen';

const appVersion = '1596709927844';
const resetLocalStorage = () => {
  const keyVersion = 'retirement-app-version';
  const keyData = 'retirement-data';
  const _appVersion = localStorage.getItem(keyVersion);
  if (appVersion !== _appVersion) {
    localStorage.setItem(keyVersion, appVersion);
    localStorage.setItem(keyData, JSON.stringify({}));
    window.location.hash = '#/your-details';
  }
};

if (document.getElementById('root')) {
  resetLocalStorage();
  const store = configure();
  const notifyResize = () => { store.dispatch({ type: 'SCREEN_RESIZE' }); };
  const Root = () => (<Provider store={store}><App /></Provider>);
  ReactDOM.render(<Root />, document.getElementById('root'));
  window.addEventListener('resize', () => { Debounced.start('resize', notifyResize, 250); });
  triggerResize('index');
} else {
  console.error('nothing to render, root not found');
}
                           
