import persistState from 'redux-localstorage';

export default () => persistState(null, {
  key: 'retirement-data',
  merge: (initialState, persistedState) => {
    // console.log({ initialState, persistedState });
    const { hash } = window.location;
    if (hash === '#/your-details') {
      persistedState = {};
    }
    if (persistedState?.keyPartnerships?.input?.birthdate) {
      persistedState.keyPartnerships.input.birthdate = new Date(persistedState.keyPartnerships.input.birthdate);
    }
    if (persistedState?.keyPartnerships?.input?.partner?.birthdate) {
      persistedState.keyPartnerships.input.partner.birthdate = new Date(persistedState.keyPartnerships.input.partner.birthdate);
    }
    return { ...initialState, ...persistedState };
  },
});