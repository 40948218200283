import React, { Component } from 'react';
import ContainerDimensions from 'react-container-dimensions';
import styled from 'styled-components';
import { IWrapper } from './types';

/* eslint-disable no-undef */

const Wrapper = styled.div`
	width: 100%;
  .cont {
    position: relative;
  }
  .touch {
    width: 10px;
    height: 10px;
    background-color: ${(props: IWrapper) => props.disabled ? '#282828' : '#F35C28'};
    border: none;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    cursor: pointer;
    left: 0;
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .line {
    background-color: #6d7085;
    /*border: 2px solid ${(props: IWrapper) => props.disabled ? '#cdcdcd' : '#b1b1b1'};*/
    border-radius: 10%;
    height: 3px;
    top: 6px;
  }
  .line-active {
    background: #F35C28;
    border-radius: 10%;
    height: 3px;
    position: absolute;
    left: 0px;
  }

  .range-label {
    padding-top: 1em;
    p {
      display: inline-block;
      color: #595959;
      font-weight: bold;
      font-size: .85em;
      &:first-child {
        float: left;
      }
      &:last-child {
        float: right;
      }
    }
  }
`;

class SliderInput extends Component {

	public state: any;
	public maxValue: any;
	public props: any;
	public touchControl: any;
	public width: any;

  constructor(props: any) {
    super(props);
    this.state = { isDragging: false, posX: 0 };

    this.maxValue = null;
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onStop = this.onStop.bind(this);
    this.calculate = this.calculate.bind(this);
    this.handleMouseDown = this.handleMouseDown.bind(this); this.getRelPosX = this.getRelPosX.bind(this);
  }
  componentDidMount() {
    if (typeof this.props.value === 'undefined' ) {
      this.props.onChange(this.props.min);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (this.state.isDragging && !prevState.isDragging) {
			document.addEventListener('touchmove', this.onMove);
			document.addEventListener('touchend', this.onStop);
      document.addEventListener('mousemove', this.onMove);
      document.addEventListener('mouseup', this.onStop);
    } else if (!this.state.isDragging && prevState.isDragging) {
      document.removeEventListener('mousemove', this.onMove);
      document.removeEventListener('touchmove', this.onMove);
      document.removeEventListener('mouseup', this.onStop);
      document.removeEventListener('touchend', this.onStop);
    }
    if (this.touchControl && this.touchControl.offsetParent) {
      this.maxValue = Math.floor(this.touchControl.offsetParent.getBoundingClientRect().width - 30);
    }
  }
  getRelPosX() {
    if (!this.width) return 0;
    const minValue = this.props.min;
    const maxValue = this.props.max;
    const value = typeof this.props.value !== 'undefined' && !isNaN(this.props.value) ? this.props.value : minValue;
    const pos = (value - minValue) / ((maxValue - minValue) / 100);
    return (pos * (this.width - 8)) / 100.0;
  }
  onStart(e: any) {
    //if (e.button !== 0 || e.touches.length !== 1) return;
    const sliderPos = this.touchControl.getBoundingClientRect();
    this.setState({
      isDragging: true,
      posX: sliderPos.left - this.getRelPosX()
    });
    e.stopPropagation();
    e.preventDefault();
  }
  onMove(e: any) {
    if (!this.touchControl) return;
    const pageX = e.pageX || (e.touches && e.touches[0].pageX) || 0;
    const maxPosX = this.touchControl.offsetParent.getBoundingClientRect().width - 30;
    const val = Math.max((pageX - 15) - this.state.posX, 0);
    const relPosX = Math.min(val, maxPosX);
    this.calculate(relPosX);
  }
  onStop(e: any) {
    if (!this.state || !this.state.isDragging) return;
    this.setState({ isDragging: false });
    e.stopPropagation();
    e.preventDefault();
  }
  calculate(relPosX: any) {
    const currentValue = Math.ceil((Math.floor(relPosX) * 100) / this.maxValue);
    const rangeValue = this.calculateRangeValue(currentValue);
    this.props.onChange(rangeValue);
  }
  handleMouseDown(e: any) {
    this.onStart(e);
  }
  calculateRangeValue(value: number) {
    const minValue = this.props.min;
    const maxValue = this.props.max;
    const koef = (maxValue - minValue) / 100;
    return minValue + Math.round(value * koef);
  }
  render() {
    const inRange = (this.props.value >= this.props.min && this.props.value <= this.props.max);
    return (
      <Wrapper disabled={this.props.isDisabled}>
        <ContainerDimensions>
          {({ width }) => {
            if (!width) return false;
            return (
              <div className='cont' >
                {inRange ? <div
                  className='touch'
                  onMouseDown={this.handleMouseDown}
                  onTouchStart={this.handleMouseDown}
                  ref={(touchControl) => { this.touchControl = touchControl; this.width = width; }}
                  style={{ left: this.getRelPosX() || 0 }}
                >
                </div>: false}
                {inRange ? <div className='line-active' style={{ width: this.getRelPosX() }}><span>&nbsp;</span></div> : false}
                <div className='line'><span>&nbsp;</span></div>
              </div>
            );
          }}
        </ContainerDimensions>
      </Wrapper>
    );
  }
}

export default SliderInput;
