import React from 'react';
import { IPastFutureArrow } from './types';

export const PastFutureArrow = (params: IPastFutureArrow) => {
  const { inactive, past, present, future, years } = params;
  const w1 = 125;
  const w2 = 1000 - w1;
  const rW = 250;
  return (
    <svg viewBox='0 0 1000 10' style={{ width: '100%', marginTop: 15, marginBottom: 0, height: 80 }}>
      <path d="M0,15 L1000,15" stroke={inactive} />
      <path d="M1000,15 L990,8" stroke={inactive} />
      <path d="M1000,15 L990,22" stroke={inactive} />
      <path d={`M${w1},-40 L${w1},15`} stroke={past} strokeWidth={1} strokeDasharray={1} />
      <path d={`M${w1},15 L${w2},15`} stroke={present} strokeWidth={3} />
      <path d={`M${w2},-40 L${w2},15`} stroke={future} strokeWidth={1} strokeDasharray={1} />
      <rect x={4*w1} y={-5} rx="5" ry="5" width={rW} height="40" fill={present} />
      <text x={4*w1+10} y={20} fontSize={14} fontWeight={600} fill="white" >{`Estimated years in retirement:`}</text>
      <text x={4*w1+rW-45} y={21} fontSize={18} fontWeight={600} fill="white" >{years}</text>
      <circle cx={w1} cy={15} r="6" fill='#F35C28' />
      <circle cx={w2} cy={15} r="6" fill='#F35C28' />
      <circle cx={3*w1} cy={15} r="6" fill='#F35C28' />
    </svg>
  );
}
