import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { TextInput } from './Form';
import { Checkbox } from './Form/Checkbox';

const minWidth = 360;
const inputBorder = '1px #f24f2d solid';

const ModalWrap = styled.div`
  .modal {
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);
  }

  .modal-content {
    background-color: #1c2332;
    margin: auto;
    min-height: 400px;
    min-width: ${minWidth}px;
  }

  .modal-header {
    color: white;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    line-height: 2;
    display: flex;
    padding-left: 30px;
    padding-right: 30px;
  }
  .modal-header img {
    width: 30px;
    height: 30px;
    margin: auto 15px auto 0px;
  }

  .modal-form {
    width: 98%;
    background-color: white;
    margin: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
`;

const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 2;
`;

const FormLabel = styled.div`
  font-weight: bold;
  padding: 10px;
  width: 40%
`;
const FormInput = styled.div`
  width: 60%;
  padding: 10px;
`;

const FormCheckbox = styled.div`
  width: 10%;
  padding-top: 10px;
  padding-left: 5px;
`;

const FormLabel2 = styled.div`
  width: 310px;
  padding: 10px;
  padding-left: 0px;
  line-height: 1.2;
`;

const DownloadButton = styled.button`
  display: block;
  background: #F35C28;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 25px;
  cursor: pointer;
  outline: none !important;
  width: fit-content;
  font-weight: 400;
  margin-top: 10px;

  &:disabled {
    cursor: default;
    background: #f35c2887;
  }
`;

interface IModal {
  onClose: any;
  onSubmit: any;
}

const Modal = ({ onClose, onSubmit }: IModal) => {

  const modalContentRef = useRef(null);

  const handleOutsideClick = (e: any) => {
    if (!modalContentRef.current.contains(e.target)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, false);
    return () => {
      document.removeEventListener('click', handleOutsideClick, false);
    };
  });

  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientCompany, setClientCompany] = useState('');
  const [receiveCalls, setReceiveCalls] = useState(false);
  const [clientPhone, setClientPhone] = useState('');
  const [agreeToPolicy, setAgreeToPolicy] = useState(false);

  const downloadEnabled = !!clientName && !!clientEmail && !!clientCompany && agreeToPolicy && (receiveCalls ? clientPhone : true);
  const data = { clientName, clientEmail, clientCompany, receiveCalls, clientPhone, agreeToPolicy };
  // console.log({ downloadEnabled, ...data });
  
  return (
    <ModalWrap>
      <div className='modal'>
        <div className="modal-content" ref={modalContentRef}>
          <div className="modal-header">
            <img src='images/printer.png' alt='printer-icon' />
            <div>Print or Save Report</div>
          </div>
          <div className='modal-form'>
            <div style={{ margin: 15 }}>
              <FormRow>
                <FormLabel>Name *</FormLabel>
                <FormInput>
                  <TextInput {...{ border: inputBorder, color: 'black', value: clientName, onChange: setClientName } } />
                </FormInput>
              </FormRow>
              <FormRow>
                <FormLabel>Email *</FormLabel>
                <FormInput>
                  <TextInput {...{ border: inputBorder, color: 'black', value: clientEmail, onChange: setClientEmail } } />
                </FormInput>
              </FormRow>
              <FormRow>
                <FormLabel>Company Name *</FormLabel>
                <FormInput>
                  <TextInput {...{ border: inputBorder, color: 'black', value: clientCompany, onChange: setClientCompany } } />
                </FormInput>
              </FormRow>
              <div style={{ width: minWidth, lineHeight: '1.2', padding: 10 }}>
                By clicking download, a notification is automatically generated and sent to your account manager who will be on hand to discuss the report in more detail if you wish.
              </div>
              <FormRow>
                <FormCheckbox>
                  <Checkbox 
                    color='#F35C28'
                    strokeWidth={2}
                    strokeOpacity={1}
                    borderRadius={0}
                    value={receiveCalls} 
                    disabled={false} 
                    onChange={(e: any) => { setReceiveCalls(!receiveCalls) }}
                  />
                </FormCheckbox>
                <FormLabel2>
                  I would like to receive a follow up call from my account manager to discuss the report in more detail.
                </FormLabel2>
              </FormRow>
              {receiveCalls ? (
                <FormRow>
                  <FormLabel>Phone *</FormLabel>
                  <FormInput>
                    <TextInput {...{ border: inputBorder, color: 'black', value: clientPhone, onChange: setClientPhone } } />
                  </FormInput>
                </FormRow>
              ) : false}
              <FormRow>
                <FormCheckbox>
                  <Checkbox 
                    color='#F35C28'
                    strokeWidth={2}
                    strokeOpacity={1}
                    borderRadius={0}
                    value={agreeToPolicy} 
                    disabled={false} 
                    onChange={(e: any) => { setAgreeToPolicy(!agreeToPolicy) }}
                  />
                </FormCheckbox>
                <FormLabel2>
                  I agree to the terms and conditions of Key Partnerships' <a href='https://www.keypartnerships.co.uk/legal/privacy-policy' style={{ color: '#f24f2d' }}>privacy policy</a> *
                </FormLabel2>
              </FormRow>
              <FormRow>
                <div></div>
                <DownloadButton onClick={() => onSubmit(data)} disabled={!downloadEnabled} >
                  DOWNLOAD
                </DownloadButton>
              </FormRow>
              
            </div>
          </div>
        </div>
      </div>
    </ModalWrap>
  );
};

export default Modal;