import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enGB from 'date-fns/esm/locale/en-GB/index';
import TextInput from './TextInput';
registerLocale('en-GB', enGB);

export const Calendar = (props: any) => {
  const { date, onChange } = props;
  const input = (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
      <TextInput {...{ value: date.toLocaleDateString('en-GB'), prefixes: ' ', onChange: () => {} }} /><img src='images/calend.svg' style={{ height: 25, padding: 3 }} alt='calend' />
    </div>
  );
  return (
    <DatePicker
      selected={props.date}
      onChange={(v: any) => onChange(v)}
      locale='en-GB'
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      customInput={input}
    />
  );
};