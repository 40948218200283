import React from 'react';
import { HomePageContainer, HomeHeader, Intro, OrangeLink } from './Layout';

const Home = (props: any) => (
  <HomePageContainer>
    <Intro {...{ ...props.screen }} />
    <HomeHeader />
    <div style={{ width: '60%' }}>
      <header>
        <div className='intro-header' style={{ fontSize: '300%' }}>Retirement funding tool</div>
        <div style={{ fontSize: 'large' }}>The complete guide to highlighting your clients assets when considering later life finances.</div>
      </header>
    </div>
    <OrangeLink href='#your-details' style={{ marginTop: '30px' }} >BEGIN</OrangeLink>
  </HomePageContainer>
);

export default Home;