import { connect } from 'react-redux';
import { Summary } from './../components/Summary';

const mapStateToProps = (state: any) => (state);
const mapDispatchToProps = (dispatch: any) => ({
    onChangeInclude: (field: any, value: any) => {
      dispatch({ type: 'UPDATE_INCLUDE', payload: { field, value } });
    },
    saveScreenshot: (screenshotId: string, dataURL: string) => {
      dispatch({ type: 'SAVE_SCREENSHOT', payload: { screenshotId, dataURL } });
    },
  });

export default connect(mapStateToProps, mapDispatchToProps)(Summary);
