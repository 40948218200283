import React, { useEffect } from 'react';
import { PageContainer, Header, Footer, Wizard, Back, Next } from './Layout';
import { InfoTip } from './Form/InfoTip';
import { PoundsInput } from './Form/PoundsInput';
import { TwoColumns2, FormRow, RowLabel2, RowContent, Panel } from './Grid';
import { toPounds } from './../services/Formats';
import { takeScreenshot } from './../services/Screenshots';
import { triggerResize } from './../services/screen';
import { IDesiredMonthlySpend } from './types';

//const link = 'https://www.ons.gov.uk/peoplepopulationandcommunity/personalandhouseholdfinances/expenditure/bulletins/familyspendingintheuk/financialyearending2017';
const link = 'a11201718rerun.xls';
const tip= (
  <div>
    The default values display figures that are based on the 2019 UK average household expenditure as reported by ONS.
    <br /><br />
    Figures are averaged across all households aged over 50 in the UK. Key Partnerships has re-grouped the figures into appropriate categories with retirement in mind. 
    For the full report, &nbsp;
    <a href={link} target="_blank" rel="noreferrer noopener">please click here</a>.
  </div>
);

const tipOther = `
Includes transfers and credit, licences and fines, tobacco, child services and education.
`;

export const DesiredMonthlySpend =(params: IDesiredMonthlySpend) => {
  const { keyPartnerships, onChangeMonthlySpend, saveScreenshot } = params;
  const { input, output } = keyPartnerships;
  const { monthlySpend } = input;
  const onChange = onChangeMonthlySpend;
  const onChangePounds = (f: any, v: any) => (onChange(f, (v === '')  ? 0: v));

  const elementId = 'desired_spend';
  useEffect(() => {
    triggerResize('desired-monthly-spend');
    takeScreenshot(elementId).then(dataURL => {
      saveScreenshot(elementId, dataURL);
    });
  }, [saveScreenshot]);
  
  return (
    <PageContainer>
      <Header title="Find out how your client's assets can fund their retirement" />

      <Panel style={{ padding: 0 }}>
        <h3 style={{ textAlign: 'center', paddingTop: 30 }}>Desired monthly spend in retirement</h3>
        <div style={{ fontSize: 12, marginBottom: 30, textAlign: 'center' }}>
          Enter your client's estimated monthly outgoings below. Don't worry if you don't know all of their<br />
          figures as we have provided the UK average household spend as a guide.
          <InfoTip value={tip} orientation='right' />
        </div>

        <div id={elementId}>
          <TwoColumns2>
            <div className="left">

              <FormRow>
                <RowLabel2>Council tax &<br />house maintenance</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.councilTax} onChange={(v: any) => onChangePounds('councilTax', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Utilities</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.utilities} onChange={(v: any) => onChangePounds('utilities', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Phone & broadband</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.phone} onChange={(v: any) => onChangePounds('phone', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Monthly food shop</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.foodDrink} onChange={(v: any) => onChangePounds('foodDrink', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Eating & drinking out</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.eating} onChange={(v: any) => onChangePounds('eating', v)} />
                </RowContent>
              </FormRow>

            </div>
            <div className="right">

              <FormRow>
                <RowLabel2>Clothing & footwear</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.clothing} onChange={(v: any) => onChangePounds('clothing', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Transport</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.transport} onChange={(v: any) => onChangePounds('transport', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Leisure & hobbies</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.leisure} onChange={(v: any) => onChangePounds('leisure', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Holidays</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.holiday} onChange={(v: any) => onChangePounds('holiday', v)} />
                </RowContent>
              </FormRow>

              <FormRow>
                <RowLabel2>Medical & personal care</RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.medical} onChange={(v: any) => onChangePounds('medical', v)} />
                </RowContent>
              </FormRow>


              <FormRow>
                <RowLabel2>Other <InfoTip value={tipOther} orientation='right' /></RowLabel2>
                <RowContent>
                  <PoundsInput value={monthlySpend.other} onChange={(v: any) => onChangePounds('other', v)} />
                </RowContent>
              </FormRow>
            </div>
          </TwoColumns2>

          <TwoColumns2 style={{ backgroundColor: "#162731", fontSize: '1.17em', padding: 5 }}>
            <div className="left" style={{ padding: 0 }}></div>
            <div className="right" style={{ padding: 0 }}>

              <FormRow style={{ paddingBottom: 0 }}>
                <RowLabel2 
                  style={{ 
                    //color: '#ffffff', fontWeight: 'bold', textAlign: 'right' 
                  }}>&nbsp;
                </RowLabel2>
                <RowContent style={{
                  minWidth: '50%', maxWidth: '50%',
                  color: '#ffffff', fontWeight: 'bold', padding: '5px 10px'
                }}>
                  Total <div style={{ float: 'right' }}>{toPounds(output.monthlySpend.total)}</div>
                </RowContent>
              </FormRow>
            </div>
          </TwoColumns2>
        </div>
      </Panel>

      <Wizard>
        <Back label="BACK" href="#/how-long-might-your-retirement-be" />
        <div className="flex"></div>
        <Next label="RESULTS" href="#/desired-spend-vs-sustainable-income" />
      </Wizard>

      <Footer/>
    </PageContainer>
  );};
