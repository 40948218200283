import React from 'react';
import styled from 'styled-components';
// import { toPounds } from './../../services/Formats';
import { IChart } from './types';

const ChartWrapper = styled.div`
  display: flex;
  position: relative;
  background: #F0F1F3;
  border-bottom: 5px #172730 solid;
  margin-top: 15px;
  margin-bottom: 30px;
  .line {
    position: absolute;
    z-index: 1;
    left: 100px;
    width:calc(100% - 100px);
    border: none;
    height:1.5px;
    background-color: #C9CBD2;
  }
  .centered {
    position: absolute;
    z-index: 100;
    top: 20px;
    left: 50%;
    width: 50%;
  }
  .find-out {
    position: relative;
    width: 300px;
    margin-top: 5px;
    margin-left: -30px;
    text-align: center;
    padding: 15px;
    background: white;
    box-shadow: 0px 5px 10px #888;
    font-size: 14px;
  }
  .find-out-button {
    display: block;
    background: #F35C28;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 10px 25px;
    cursor: pointer;
    outline: none !important;
    width: 100%;
    font-weight: 400;
    margin-top: 10px;
  }
`;

export const Chart = (params: IChart) => {
  const { height, maxValue, screen, children } = params;
  // increase step by 500 until number of division is less than 500
  let step = 0;
  let divs = Math.floor(maxValue / step);
  do {
    step += 500
    divs = Math.floor(maxValue / step);
  } while (divs > 12)
  if (isNaN(divs)) return null;
  const dy = height / divs;
  const style= {...{ height }};
  return (
    <div id={params.elementId} style={{ height: (screen && screen.width > 1200) ? height + 60 : height + 90 }}>
      <ChartWrapper style={style}>
        {[...Array(divs).keys()].map(n => (
          <div key={n + 1} className="line" style={{ top: height - dy * n }}></div>
        ))}
        <div key={0} className="line" style={{ top: height - dy * divs }}></div>
        {children}
      </ChartWrapper>
    </div>
  );
}
