import React from 'react';
import styled from 'styled-components';
import { toPounds } from './../../services/Formats';
import { IChartColumn } from './types';

const ChartColumnWrapper = styled.div`
   flex: 1;
   position: relative;
   vertical-align: bottom;
   padding-bottom: 30px;

   .legend {
     position: absolute;
     left: 0px; width: 100%; 
     bottom: 0px;
     padding-top: 8px;
     text-align: center;
   }
   
  .centered {
    position: absolute;
    z-index: 10;
    top: 0px;
    left: 50%;
    width: 50%;
  }
  .value {
    position: absolute;
    z-index: 11;
    margin-left: -40px;
    width: 80px;
    text-align: center;
    font-weight: bold;
    white-space: nowrap;
  }
  .rect {
    position: absolute;
    width: 80px;
    margin-left: -40px;
  }

  .coin {
     margin-left: -70px;
     opacity: 0.9;
     width: 80px;
     position: absolute;
     text-align: center;
     color: white;
     font-size: 20px;
     font-weight: bold;
   }
   .coin svg {
     width: 220px;
     height: 60px;
     top: -10px;
     left: -40px;
     position: absolute;
   }
   .coin .pound {
     text-align: center;
     position: absolute;
     top: 0px;
     left: 0px;
     width: 140px;
     text-align: center;
     color: white;
     font-size: 24px;
     font-weight: bold;
   }
`;

export const ChartColumn = (params: IChartColumn) => {
  const { verbose, label, value, colors, values, background, backgrounds, height, screen, maxValue, isShortfall = false } = params;
  const dy = height / maxValue;

  const colorRange: any[] = [];
  if (values && colors) {
    let acc = 0
    values.forEach((val: number, index: number) => {
      const colHeight = (val * dy > 0) ? val * dy : 0;
      colorRange.push({ from: acc, h: colHeight, color: colors[index] });
      acc += colHeight;

    });
    // console.log(colorRange);
  }
  const divs = Math.max(colorRange.length, 1);
  
  // const getColor = n => {
  //   if (color) return color;
  //   if (colors) return colorRange[n].color;
  //   return '#ff0';
  // };
  const getBk = (n: number) => {
    const bgStyle = { backgroundImage: 'url("images/coins/green.png")', backgroundPosition: '0px 0px', backgroundRepeat: 'repeat-y', };
    if (background) return { ...bgStyle, backgroundImage: `url("${background}")`, }; //`url("${background}") 0px 0px repeat-y`;
    if (backgrounds) return { ...bgStyle, backgroundImage: `url("${backgrounds[n]}`, }; //`url("${backgrounds[n]}") 0px 0px repeat-y`;
    return bgStyle; //return 'url("images/coins/green.png") 0px 0px repeat-y';
  };
  const getBkSize = (n: number) => {
    const val = values ? values[n] : value;
    const colHeight = val * dy;
    if (colHeight < 50) return 'cover';
    return 'contain';
  };

  const heightWithLegend = (screen && screen.width > 1200) ? height : height + 30;
  const isCol3 = label === 'Monthly Shortfall/Surplus';

  return (
    <ChartColumnWrapper style={{ height: heightWithLegend }}>
      <div className="centered">
        <div className="value"
            style={{ top: height - value * dy - 20, fontWeight: isCol3 ? 'bold' : 'inherit', color: isShortfall ? '#c0001b' : 'inherit' }}
        >
          {`${isCol3 ? (isShortfall ? '-' : '+') : ''}${toPounds(value)}`}
        </div>

        {[...Array(divs).keys()].map(n => {
          const from = values ? height - colorRange[n].from : height;
          const h = values ? colorRange[n].h : value * dy;
          return (
            <div
              key={n} className="rect" 
              style={{ 
                border: verbose ? '1px #ccc solid' : 'none',
                top: from - h, height: h, 
                //background: getBk(n), 
                ...getBk(n),
                backgroundSize: getBkSize(n) }}>
            </div>
          );
        })}
      </div>
      <div
        className="legend"
        style={{ 
          color: isShortfall ? '#c0001b' : 'inherit', 
          fontWeight: isCol3 ? 'bold' : 'inherit' 
        }}
      >
        {label}
      </div>
    </ChartColumnWrapper>
  );
}


