import { getAvgLifeExpectancy } from './AvgLifeExpectancy';
import { IPerson } from './types';

interface ILTVs {
  readonly [index :number]: number,
};

// const LTVs: ILTVs = {
//   55:	25.5, 56:	26.5, 57:	27.5, 58:	28.5, 59:	29.5, 60:	31, 61:	32, 62:	33, 63:	34, 64:	35,
//   65:	36, 66:	37, 67:	38, 68:	39, 69:	40, 70:	41.1, 71:	42.2, 72:	43.4, 73:	44.6, 74:	45.8,
//   75:	47, 76:	48, 77:	49, 78:	50, 79:	50.5, 80:	51.5, 81:	52.2, 82:	53, 83:	53, 84:	53,
//   85:	54, 86:	54, 87:	54, 88:	54, 89:	54, 90:	54, 91: 54, 92: 54, 93: 54, 94: 54, 95: 54
// };

const LTVs: ILTVs = {
  55:	28,
  56:	29,
  57:	30,
  58:	31, 
  59:	32, 
  60:	33, 
  61:	34, 
  62:	35, 
  63:	36, 
  64:	37,
  65:	38, 
  66:	39, 
  67:	40, 
  68:	41, 
  69:	42, 
  70:	43, 
  71:	44, 
  72:	45, 
  73:	46, 
  74:	47,
  75:	48, 
  76:	49, 
  77:	50, 
  78:	51, 
  79:	52, 
  80:	53, 
  81:	54, 
  82:	55, 
  83:	56, 
  84:	56,
  85:	56, 
  86:	56.5, 
  87:	56.5,
  88:	56.5,
  89:	56.5,
  90:	56.5, 
  91: 54, 
  92: 54, 
  93: 54, 
  94: 54, 
  95: 54
};

const safeVal = (num: any) => (
  (typeof num === 'undefined' || isNaN(num) || num === null || !isFinite(num)) ? 0 :
    parseFloat(typeof num !== 'number' ? parseFloat(num).toFixed(2) : num.toFixed(2))
);

const round = (num: number, percision: number = 10) => Math.round((num + Number.EPSILON) * percision) / percision;

export const getMinRetAge = (p1: IPerson, p2: IPerson) => {
  const isRetired1 = (p1.retAge <= p1.age);
  const isRetired2 = (p2.retAge <= p2.age);
  if (isRetired1 && isRetired2) {
    return (p1.age <= p2.age) ? p1 : p2;
  }
  if (!isRetired1 && !isRetired2) {
    return p1.retAge - p1.age < p2.retAge - p2.age ? p1 : p2;
  }
  if (isRetired1) return p1;
  if (isRetired2) return p2;
};

export const getMaxLifePartner = (p1: IPerson, p2: IPerson) => {
  //return (p1.age <= p2.age) ? p1 : p2;
  return p1.lifeExpectancy - p1.age < p2.lifeExpectancy - p2.age ? p2 : p1;
};

export const getMinStatePensionAgePartner = (p1: IPerson, p2: IPerson) => (
  p1.statePensionAge - p1.age <= p2.statePensionAge - p2.age ? p1 : p2
);

export const getOutput = (input: any) => {
  const { monthlySpend } = input;
  const monthlySpendTotal = safeVal(Object.keys(monthlySpend).map(key => monthlySpend[key])
    .filter(x => (x && !isNaN(x) && parseFloat(x) > 0.001))
    .reduce((a, val) => (a + parseFloat(val)), 0));

  const { age, birthdate, retAge, gender, withPartner, partner, pension, saving, mortgageVal, outstandingDebt, statePensionAmount, statePensionAge, percentHouse, lifeExpectancy, customAvgLifeExpectancy } = input;
  // if a couple, define youngest retired (not the person who retired first)
  const personIsRetired = ( retAge <= age );
  const partnerIsRetired = withPartner && (partner.retAge <= partner.age);
  const isRetired = partnerIsRetired || personIsRetired;
  
  const person = { age, birthdate, gender, retAge, statePensionAge, lifeExpectancy };
  const minRetAgePartner = withPartner ? getMinRetAge(person, partner) : person;
  const minStatePensionAgePartner = withPartner ? getMinStatePensionAgePartner(person, partner) : person;
  const maxLifePartner = withPartner ? getMaxLifePartner(person, partner) : person;
  const ageInCalculations = minRetAgePartner.age;
  const minLifeExpectancy = (isRetired ? maxLifePartner.age : minRetAgePartner.retAge);
  const avgLifeExpectancy = !!customAvgLifeExpectancy && customAvgLifeExpectancy > minLifeExpectancy ? customAvgLifeExpectancy : getAvgLifeExpectancy(maxLifePartner);
  //const years = avgLifeExpectancy - ageInCalculations; // life years
  const years = round(avgLifeExpectancy - minLifeExpectancy); // years in retirement
  // const yearsPerson = round(lifeExpectancy - (isRetired ? age : retAge));
  const months = years * 12;
  // const monthsPerson = yearsPerson * 12;
  const statePensionPerMonth = statePensionAmount || safeVal(164.35 * 52 / 12);
  const combinedStatePensionPerMonth = withPartner && partner.statePension ? statePensionPerMonth + (partner.statePensionAmount || safeVal(164.35 * 52 / 12)) : statePensionPerMonth;
  const yearsUntilRetirement = minRetAgePartner.retAge - minRetAgePartner.age > 0 ? minRetAgePartner.retAge - minRetAgePartner.age : 0;
  const homeValAtRetirement = safeVal(input.homeVal) * Math.pow((1 + percentHouse / 100), yearsUntilRetirement);
  // const homeValAtRetirement = safeVal(input.homeVal) * (1 + percentHouse / 100);
  const ltv = LTVs[minRetAgePartner.retAge];
  const equityAvailableToRelease = safeVal(ltv / 100) * homeValAtRetirement;
  const savingsMinusDebt = safeVal(saving) - (safeVal(mortgageVal) + safeVal(outstandingDebt));

  return {
    statePension: statePensionPerMonth * months,
    summary: {
      savingsMinusDebt,
      statePensionPerYear: safeVal(statePensionPerMonth * 12),
      combinedStatePensionPerYear: safeVal(combinedStatePensionPerMonth * 12),
      privatePensions: safeVal(pension),
      homeValAtRetirement,
      equityAvailableToRelease,
      ltv,
    },
    ageInCalculations,
    avgRetirement : {
      age: ageInCalculations,
      retAge: minRetAgePartner.retAge,
      statePensionAge: minStatePensionAgePartner.statePensionAge,
      isRetired,
      years,
      avgLifeExpectancy,
      minLifeExpectancy,
    },
    retirement : {
      age: ageInCalculations,
      statePensionAge: input.statePensionAge,
      isRetired,
      years,
      lifeExpectancy: input.lifeExpectancy
    },
    monthlySpend: {
      total: monthlySpendTotal.toFixed(2)
    },
    monthly: {
      savings: safeVal(savingsMinusDebt / months),
      statePension: statePensionPerMonth,
      combinedStatePension: safeVal(combinedStatePensionPerMonth),
      privatePension: safeVal(safeVal(pension) / months),
      lifetimeMortgage: safeVal( equityAvailableToRelease / months ),
    }
  };
}
