import React from 'react';
import { Link } from 'react-router-dom';
import styled  from 'styled-components';
import { ITitle } from './types';

export const CenteredHeader = styled.h3`
  padding-bottom: 30px;
  text-align: center;
`;

export const HomePageContainer = styled.div`
  margin: 40px 40px;
  padding: 15px 15px;
  flex-grow: 1;
`;

export const PageContainer = styled.div`
  margin: 20px 20px;
  padding: 15px 15px;
`;

export const Intro = (screen: any) => {
  const { 
    width: sWidth, 
    //height: sHeight, 
    container: {
      //x, 
      //y, 
      width, 
      height, 
      //top, 
      right, 
      //bottom, 
      //left
  } } = screen;
  const src = 'intro.png';
  // original height and width of intro.png:
  const origWidth = 1012;
  const origHeight = 909;
  const proportionalWidth = (origWidth * height) / origHeight;
  const style: React.CSSProperties = {
    position: 'absolute',
    height: `${height}px`,
    width: `${proportionalWidth}px`,
    top: '0%',
    right: `${sWidth - right + 12}px`,
    zIndex: -1,
  };
  if (width < proportionalWidth) {
    style.width = `${width}px`;
    //style.height = `${height}px`;
    style.objectFit = 'cover';
    style.objectPosition = '59% center';
  }
  return (
    <img 
      src={`images/${src}`}
      alt=''
      style={style}
    />
  );
}
// Only for intro page:
export const HomeHeader = () => (
  <div style={{ marginBottom: '20px' }}>
    <div><img src="images/logo.png" alt="Key Partnerships" style={{ width: '200px', height: 'auto' }} /></div>
  </div>
);

// For all the rest pages:
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .title {
    flex: 1; 
    text-align: right;
    color: #f25c28;
    font-size: 17px;
    font-weight: bold;
  }
  .logo img { width: 100px; height: auto; }
`;
// border-bottom: 3px #e52713 solid;

export const Header = (props: ITitle) => {
  const { title } = props;
  return (
    <HeaderWrapper>
      <div className="logo">
        <Link to='/' >
          <img src="images/logo.png" alt="Key Partnerships" />
        </Link>
      </div>
      <div className="title">{title}</div>
    </HeaderWrapper>
  );
};

const FooterWrapper = styled.div`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 0px;
  a { margin-left: 5px; margin-right: 5px; font-size: 13px; }
`;

export const Footer = () => (
  <FooterWrapper>
  {/*
      <Link to='/your-details'>Your Details</Link>
      <Link to='/summary'>Summary</Link>
      <Link to='/how-long-might-your-retirement-be'>How long...</Link>
      <Link to='/desired-monthly-spend-in-retirement'>Monthly Spend</Link>
      <Link to='/desired-spend-vs-sustainable-income'>Spend vs Income</Link>
    */}
  </FooterWrapper>
);

export const Wizard = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;

  .label { font-size: 12px; font-weight: 400; line-height: 18px; }
  .flex { flex: 1;  }

  @media(max-width: 800px) {
    display: block;
    .flex { flex: none; margin-top: 2px; }
  }
`;

export const RedButton = styled.a`
  display: block;
  width: fit-content;
  color: white !important;
  cursor: pointer;
  background: #F35C28;
  border-radius: 25px;
  padding: 10px 18px 10px 18px;
  text-decoration: none !important;
  text-align: center;
  position: relative;
  font-size: 15px;
  margin: 5px;

  &.disabled {
    background: white !important;
    color: gray !important;
    border: 3px gray solid;
    cursor: default;
  }
`;

export const GreyButton = styled.a`
  display: block;
  width: fit-content;
  color: white !important;
  cursor: pointer;
  background: #6C7286;
  border-radius: 25px;
  padding: 10px 18px 10px 18px;
  text-decoration: none !important;
  text-align: center;
  position: relative;
  font-size: 15px;
  margin: 5px;

  &.disabled {
    background: white !important;
    color: gray !important;
    border: 3px gray solid;
    cursor: default;
  }
`;

export const BlackButton = styled.a`
  display: block;
  width: fit-content;
  min-width: 150px;
  color: white !important;
  cursor: pointer;
  background: #162730;
  border-radius: 25px;
  padding: 10px 18px 10px 18px;
  text-decoration: none !important;
  text-align: center;
  position: relative;
  font-size: 15px;
  margin: 5px;

  &.disabled {
    color: gray !important;
    background-color: #405865 !important;
    cursor: default;
    pointer-events: none;
  }
`;

export const Spinner = styled.div`
  display: inline-block;
  margin: auto;

  &:after {
    content: " ";
    display: block;
    border-radius: 50%;
    border: 6px solid #fff;
    height: 8px;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 30px;
  margin-left: 100px;
  flex-wrap: wrap;
`;

export const OrangeLink = styled.a`
  display: inline-block;
  color: #ffffff !important;
  cursor: pointer;
  background: #f25c28;
  border: 2px #f25c28 solid;
  border-radius: 25px;
  padding: 10px 5px;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  position: relative;
  font-size: 16px;

  &.disabled {
    background: white !important;
    color: gray !important;
    border: 2px gray solid;
    cursor: default;
  }
`;

export const TransparentLink = styled.a`
  display: inline-block;
  color: #f25c28 !important;
  cursor: pointer;
  background: #white;
  border: 2px #f25c28 solid;
  border-radius: 25px;
  padding: 5px 20px;
  text-decoration: none !important;
  min-width: 150px;
  text-align: center;
  position: relative;
  font-size: 13px;
  font-weight: bold;
  margin: 10px;
`;

export const OrangeButton = styled.a`
  display: block;
  color: white !important;
  cursor: pointer;
  background: #F35C28;
  border-radius: 25px;
  padding: 5px 10px;
  text-decoration: none !important;
  text-align: center;
  position: relative;
  font-size: 18px;

  &.disabled {
    background: white !important;
    color: gray !important;
    border: 3px gray solid;
    cursor: default;
  }
`;

export const Clickable = styled.a`
  display: block;
  color: #ee7402 !important;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 0px;
  padding: 5px 10px;
  text-decoration: underline !important;
  text-align: center;
  position: relative;
  font-size: 18px;

  &.disabled {
    color: gray !important;
    cursor: default;
  }
`;



const ChevronRight = ({ style = {} }) => (
  <svg viewBox="0 0 256 512"  style={{ ...style, width: 7 }}>
    <path fill="currentColor" stroke="white" strokeWidth="40" d="M17.525 36.465l-7.071 7.07c-4.686 4.686-4.686 12.284 0 16.971L205.947 256 10.454 451.494c-4.686 4.686-4.686 12.284 0 16.971l7.071 7.07c4.686 4.686 12.284 4.686 16.97 0l211.051-211.05c4.686-4.686 4.686-12.284 0-16.971L34.495 36.465c-4.686-4.687-12.284-4.687-16.97 0z" />
  </svg>
);
const ChevronLeft = ({ style = {} }) => <ChevronRight style={{ ...style, transform: 'scale(-1, 1)' }} />;

export const Next = (props: any) => {
  return (
    <RedButton {...props} className={props.disabled ? 'disabled' : ''} >
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        <div style={{ whiteSpace: 'nowrap' }}>{props.label}</div>
        {props.chevron !== false ? <ChevronRight style={{ marginLeft: 10 }} /> : false}
      </div>
    </RedButton>
  );
}

export const Back = (props: any) => {
  return (
    <GreyButton {...props} className={props.disabled ? 'disabled' : ''} >
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {props.chevron !== false ? <ChevronLeft style={{ marginRight: 10 }} /> : false}
        <div style={{ whiteSpace: 'nowrap' }}>{props.label}</div>
      </div> 
    </GreyButton>
  )
}

export const Print = (props: any) => {
  return (
    <BlackButton { ...props } className={props.disabled ? 'disabled' : ''}>
      <div style={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center' }}>
        {props.waiting ? <Spinner /> : false}
        <div style={{ whiteSpace: 'nowrap', margin: 'auto' }}>
          {props.label}
        </div>
      </div> 
    </BlackButton>
  );
};
