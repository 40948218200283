import React, { useEffect, useState } from 'react';
import { PageContainer, TransparentLink, Header, Footer, Wizard, Buttons, Back, Print } from './Layout';
import { Panel, WithSidebar, FormRow, RowContent, CheckboxRow } from './Grid';
import { InfoTip } from './Form/InfoTip';
import SliderInput from './Form/SliderInput';
import { Checkbox } from './Form/Checkbox';
import { TextOutput } from './Form/TextOutput';
import Modal from './Modal';
import { toPounds, roundedTo500 } from './../services/Formats';
import { exportToPdf } from './../services/ExportToPDF';
import { Chart, ChartColumn, ChartAxisY } from './Chart/index';
import { IDesiredSpendVsSustainableIncome } from './types';
import { takeScreenshot } from './../services/Screenshots';
import { SummarySavingsSVG, SummaryPrivatePensionSVG, SummaryStatePensionSVG, SummaryLifetimeMortgageWhiteSVG, SummaryLifetimeMortgageOrangeSVG } from './Icons/index';
import { sendManagerNotification } from './../services/ApiRequest';
import { parse } from './../services/ParseInput';
import { triggerResize } from './../services/screen';
import Debounced from './../services/Debounced';

const getFloatVal = (val: any) => {
  const _val = parseFloat(val);
  return isNaN(_val) ? 0 : _val;
};

const tipDisabledLifetimeMortgage = `
   To be eligible for a lifetime mortgage you
   will require alternative funds to pay off your mortgage debt in full.
`;
const tipStatePension = `Your client would have started receiving this amount when they reached state pension age. They may be entitled to receive more if they defer their claim and they retire past the state pension age. If a couple, this is the sum of both the client and their partner's state pension.`;
const tipMonthlyLifetimeMortgage = `This figure is for comparison, your client will not get paid monthly with a Lifetime Mortgage. It is calculated using the maximum amount of equity that can be released divided by the number of months of life expectancy.`;
const tipSavings = `This is your client's total savings minus debt calculated monthly over their estimated years in retirement.`;

export const DesiredSpendVsSustainableIncome = (params: IDesiredSpendVsSustainableIncome) => {
  const { keyPartnerships, screen, onChangeInput, onChangeInclude, screenshots, saveScreenshot } = params;
  const { input, output } = keyPartnerships;
  const { include } = input;
  const { summary, monthly, avgRetirement: { avgLifeExpectancy, minLifeExpectancy } } = output;
  const { savingsMinusDebt, equityAvailableToRelease } = summary;

  const [printing, setPrinting] = useState(false);
  const [dialog, showDialog] = useState(false);

  const monthlyTotal = parseFloat(output.monthlySpend.total);
  let incomeTotal = 0;
  let incomeTotalWithLTM = 0;
  if (include.savings) {
    incomeTotal += getFloatVal(monthly.savings);
  }
  if (include.statePension) {
    incomeTotal += getFloatVal(monthly.combinedStatePension);
  }
  if (include.privatePension) {
    incomeTotal += getFloatVal(monthly.privatePension);
  }
  if (include.lifetimeMortgage) {
    incomeTotal += getFloatVal(monthly.lifetimeMortgage);
  }
  incomeTotalWithLTM = incomeTotal;
  if (!include.lifetimeMortgage) {
    incomeTotalWithLTM += getFloatVal(monthly.lifetimeMortgage);
  }

  const shortfallSurplus = (incomeTotal - monthlyTotal).toFixed(2);
  const shortfallSurplusWithLTM = (incomeTotalWithLTM - monthlyTotal).toFixed(2);
  const isShortfall = parseFloat(shortfallSurplus) <= 0;
  const isShortfallWithLTM = parseFloat(shortfallSurplusWithLTM) <= 0;
  const isInitialShortfall = (incomeTotal - parseFloat(monthly.lifetimeMortgage) - monthlyTotal) <= 0;

  const hideMessage = savingsMinusDebt > 0 || equityAvailableToRelease > Math.abs(savingsMinusDebt);
  const disabledLTM = hideMessage ? false : !include.lifetimeEligible;
  const qMult = disabledLTM ? 1.3 : (include.lifetimeMortgage ? 1.5 : 2.2);
  const maxValue = roundedTo500(Math.max(monthlyTotal, incomeTotal) * qMult);
  const maxValueWithLTM = roundedTo500(Math.max(monthlyTotal, incomeTotalWithLTM) * 1.5);
  const height = 400;
  const disableReport = !screenshots.all;
  const includeLTM = include.lifetimeMortgage;

  useEffect(() => {
    triggerResize('desired-spend-vs-sustainable-income');
    Debounced.start('take_screenshot', () => {
      console.log('take screenshot');
      const elementIds = ['chart_without_mortgage', 'chart_with_mortgage', 'savings', 'private_pension', 'state_pension', 'lifetime_mortgage_with_mortgage'];
      for (let i = 0; i < elementIds.length; i++) {
        const elementId = elementIds[i];
        takeScreenshot(elementId).then(dataURL => {
          saveScreenshot(elementId, dataURL);
        });
      }
    });
  }, [saveScreenshot, avgLifeExpectancy]);

  useEffect(() => {
    triggerResize('desired-spend-vs-sustainable-income');
  }, [isShortfall, includeLTM]);

  const onSubmit = (data: any) => {
    showDialog(false);
    setPrinting(true);
    exportToPdf({ input, output, screenshots, isShortfall: isInitialShortfall, }).then((pdfDocBase64) => {
      setPrinting(false);
      sendManagerNotification({ ...data, input: parse(input), pdfDocBase64 }).then((response: any) => {
        console.log(response);
      }).catch((error: any) => {
        console.error(error);
      });
    });
  };

  return (
    <React.Fragment>
      {dialog ? <Modal onClose={() => showDialog(false)} onSubmit={onSubmit} /> : false}
      <PageContainer>
        <Header title="Find how your client's assets could fund their retirement" />
        <Panel>
          <h3 style={{ textAlign: "center" }}>Desired spend vs. sustainable income</h3>
          <p style={{ fontSize: 12, textAlign: "center", marginBottom: 30 }}>
            The chart below shows your client's desired monthly spend vs. their estimated sustainable<br />
            monthly income (based on your client's assets and their average life expectancy).
          </p>
          <WithSidebar>
            <div className="sidebar">
              <h4 style={{ marginBottom: 10, width: 200, marginTop: 0 }}>
                Select the assets<br />
                they want to use:
              </h4>

              <div id='assets_with_mortgage'>
                <div id='assets_without_mortgage'>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div id='savings' style={{ width: 200 }}>
                      <CheckboxRow style={{ background: '#155E89', borderRadius: 10, }}>
                        <Checkbox color='#155E89' value={include.savings} onChange={(v: any) => onChangeInclude('savings', v)} disabled={false} />
                        {/* <img src='images/infographics/summary1.1.svg' alt='savings' /> */}
                        <SummarySavingsSVG width='28%' height='28%' />
                        <div className="text">
                          <div className="title">Savings</div>
                          <div className="note">{toPounds(monthly.savings)} a month</div>
                        </div>
                      </CheckboxRow>
                    </div>
                    <InfoTip value={tipSavings} />
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div id='private_pension' style={{ width: 200 }}>
                      <CheckboxRow style={{ background: '#6C7286', borderRadius: 10 }}>
                        <Checkbox color='#6C7286' value={include.privatePension} onChange={(v: any) => onChangeInclude('privatePension', v)} disabled={false} />
                        <SummaryPrivatePensionSVG width='28%' height='28%' />
                        {/* <img src='images/infographics/summary3.1.svg' alt='private_pension' /> */}
                        <div className="text">
                          <div className="title" >Private pension</div>
                          <div className="note" >{toPounds(monthly.privatePension)} a month</div>
                        </div>
                      </CheckboxRow>
                    </div>
                  </div>
                  
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div id='state_pension' style={{ width: 200 }}>
                      <CheckboxRow style={{ background: '#162730', borderRadius: 10 }}>
                        <Checkbox color='#162730' value={include.statePension} onChange={(v: any) => onChangeInclude('statePension', v)} disabled={false} />
                        <SummaryStatePensionSVG width='28%' height='28%' />
                        {/* <img src='images/infographics/summary2.1.svg' alt='state_pension' /> */}
                        <div className="text">
                          <div className="title" >State pension</div>
                          <div className="note" >{toPounds(monthly.combinedStatePension)} a month</div>
                        </div>
                      </CheckboxRow>
                    </div>
                    <InfoTip value={tipStatePension} />
                  </div>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 200 }}>
                    <CheckboxRow style={{ background: '#F35C28', borderRadius: 10 }}>
                      <Checkbox disabled={disabledLTM} color='#F35C28' value={include.lifetimeMortgage} onChange={(v: any) => onChangeInclude('lifetimeMortgage', v)} />
                      <SummaryLifetimeMortgageWhiteSVG width='28%' height='28%' />
                      {/* <img src='images/infographics/summary4.1.svg' alt='lifetime_mortgage' /> */}
                      <div className="text">
                        <div className="title" style={{ display: 'flex', lineHeight: (disabledLTM) ? '24px': 'auto' }}>
                          {disabledLTM ? <InfoTip value={tipDisabledLifetimeMortgage} /> : false}
                          {disabledLTM ? <span> &nbsp;  &nbsp; </span> : false}
                          Lifetime Mortgage
                        </div>
                        {!disabledLTM ? <div className="note">{toPounds(monthly.lifetimeMortgage)} a month</div>: false}
                      </div>
                    </CheckboxRow>
                  </div>
                  <InfoTip value={tipMonthlyLifetimeMortgage} />
                </div>

                <div style={{ position: 'absolute', left: '-3000px' }}>
                  <div id='lifetime_mortgage_with_mortgage' style={{ width: 200 }}>
                    <CheckboxRow style={{ background: '#F35C28', borderRadius: 10 }}>
                      <Checkbox disabled={false} color='#F35C28' value={true} onChange={(v: any) => onChangeInclude('lifetimeMortgage', v)} />
                      <SummaryLifetimeMortgageWhiteSVG width='28%' height='28%' />
                      {/* <img src='images/infographics/summary4.1.svg' alt='lifetime_mortgage' /> */}
                      <div className="text">
                        <div className="title" style={{ display: 'flex', lineHeight: (disabledLTM) ? '24px': 'auto' }}>
                          {disabledLTM ? <InfoTip value={tipDisabledLifetimeMortgage} /> : false}
                          {disabledLTM ? <span> &nbsp;  &nbsp; </span> : false}
                          Lifetime Mortgage
                        </div>
                        {!disabledLTM ? <div className="note">{toPounds(monthly.lifetimeMortgage)} a month</div>: false}
                      </div>
                    </CheckboxRow>
                  </div>
                </div>
              </div>

              <div style={{ width: 200 }}>
                <h4 style={{ marginBottom: 0 }}>Life Expectancy:</h4>
                <p style={{ fontSize: 11 }}>
                  (if a couple this is the age of the person<br />who is predicted to live the longest)
                </p>
              </div>

              <FormRow style={{ width: 200 }}>
                <RowContent style={{ marginRight: 10 }}>
                  <SliderInput value={avgLifeExpectancy} min={minLifeExpectancy + 1} max={100} onChange={(v: any) => (onChangeInput('avgLifeExpectancy', v))} />
                </RowContent>
                <TextOutput value={avgLifeExpectancy.toString()} />
              </FormRow>
            </div>

            <div className="content">
              <Chart {...{ height, maxValue, screen, elementId: 'chart_without_mortgage' }}>
                {!disabledLTM && !include.lifetimeMortgage ? (
                  <div className="centered">
                    <div className="find-out">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <SummaryLifetimeMortgageOrangeSVG width='70px' height='61px' />
                        <div style={{ width: 230 }}>
                          Find out how releasing the equity<br />
                          in your client's home increases<br />
                          their sustainable monthly income.
                        </div>
                      </div>

                      <button
                        onClick={() => (onChangeInclude('lifetimeMortgage', true))}
                        className="find-out-button"
                      >ADD LIFETIME MORTGAGE</button>
                    </div>
                  </div>
                ) : false}
                <ChartAxisY {...{height, maxValue}} />
                <ChartColumn
                  label='Desired Monthly Spend'
                  value={monthlyTotal}
                  background='images/coins/white.png'
                  {...{height, maxValue, screen}} 
                  />
                <ChartColumn
                  label='Sustainable Monthly Income'
                  value={incomeTotal.toFixed(2)}
                  values={[
                    include.lifetimeMortgage ? parseFloat(monthly.lifetimeMortgage) : 0,
                    include.privatePension ? parseFloat(monthly.privatePension) : 0,
                    include.savings ? parseFloat(monthly.savings) : 0,
                    include.statePension ? parseFloat(monthly.combinedStatePension) : 0
                  ]}
                  colors={[ '#6cc8d4', '#f9b259', '#ee7402', '#9923c4']}
                  backgrounds={[ 
                    'images/coins/flamingo.png',
                    'images/coins/stormgrey.png',
                    'images/coins/blumine.png',
                    'images/coins/gablegreen.png'
                  ]}
                  {...{height, maxValue, screen}} 
                />
                <ChartColumn
                  label='Monthly Shortfall/Surplus'
                  background={`images/coins/${isShortfall ? 'amaranth' : 'silver'}.png`}
                  value={Math.abs(parseFloat(shortfallSurplus))}
                  {...{ height, maxValue, screen, isShortfall }} 
                />
              </Chart>

              <div style={{ position: 'absolute', left: '-2000px', width: '800px' }}>
                <Chart {...{ height, maxValue: maxValueWithLTM, screen, elementId: 'chart_with_mortgage' }}>
                  <ChartAxisY {...{height, maxValue: maxValueWithLTM }} />
                  <ChartColumn
                    label='Desired Monthly Spend'
                    value={monthlyTotal}
                    background='images/coins/white.png'
                    {...{height, maxValue: maxValueWithLTM, screen}} 
                    />
                  <ChartColumn
                    label='Sustainable Monthly Income'
                    value={incomeTotalWithLTM.toFixed(2)}
                    values={[
                      true ? parseFloat(monthly.lifetimeMortgage) : 0,
                      include.privatePension ? parseFloat(monthly.privatePension) : 0,
                      include.savings ? parseFloat(monthly.savings) : 0,
                      include.statePension ? parseFloat(monthly.statePension) : 0
                    ]}
                    colors={[ '#6cc8d4', '#f9b259', '#ee7402', '#9923c4']}
                    backgrounds={[ 
                      'images/coins/flamingo.png',
                      'images/coins/stormgrey.png',
                      'images/coins/blumine.png',
                      'images/coins/gablegreen.png'
                    ]}
                    {...{height, maxValue: maxValueWithLTM, screen}} 
                  />
                  <ChartColumn
                    label='Monthly Shortfall/Surplus'
                    background={`images/coins/${isShortfallWithLTM ? 'amaranth' : 'silver'}.png`}
                    value={Math.abs(parseFloat(shortfallSurplusWithLTM))}
                    {...{ height, maxValue: maxValueWithLTM, screen, isShortfall: isShortfallWithLTM }} 
                  />
                </Chart>
              </div>

              <Buttons>
                <TransparentLink href="#/desired-monthly-spend-in-retirement">
                  Edit spend details
                </TransparentLink>
                <TransparentLink href="#/your-details">
                  Edit financial details
                </TransparentLink>
              </Buttons>
            </div>
          </WithSidebar>
          {isShortfall ? (
            <div style={{ padding: 25, background: '#EFF0F2' }}>
              <strong>Tip: </strong>
              You may want to consider asking your client to reduce their desired monthly spend by taking out non-essential costs (e.g. holidays and eating out) or consider a Lifetime Mortgage.
            </div>
          ) : false}
          {(include.lifetimeMortgage && parseFloat(monthly.lifetimeMortgage) > 0 ) ? (
            <div style={{ padding: 25, background: '#EFF0F2' }}>
              The monthly Lifetime Mortgage figure is based on the maximum
              loan on a standard LTV.
              The calculation is based on your client's current age 
              (youngest in the joint mortgage cases) 
              and the anticipated property value at retirement. 
              This has been divided by the number of months over your client's life expectancy.
            </div>
          ): false}
        </Panel>

        <Wizard>
          <Back label="BACK" href="#/desired-monthly-spend-in-retirement" />
          <div className="flex"></div>
          <Back style={{ background: '#F35C28' }} label="REVIEW & EDIT DATA" href="#/your-details" />
          <div className="nbsp">&nbsp;</div>
          <Print disabled={disableReport} waiting={printing} label="PRINT/SAVE" onClick={() => { 
            showDialog(true);
          }}/>
        </Wizard>

        <Footer />
      </PageContainer>
    </React.Fragment>
  );
};
